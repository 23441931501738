import WidgetContainer from "./Components/WidgetContainer";
import { GrazProvider, WalletType } from "graz";
import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import "./index.css";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { Buffer } from "buffer";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import { useEffect, useMemo } from "react";
import useStore from "./zustand/store";
import { isEmpty } from "lodash";
import getParams from "./utils/getParams";
import ToggleButton from "./Components/Toggle";
import setColors from "./utils/setColors";
import config from "./service/wagmiConfig";
import SendTx from "./Components/SendTx";
import useCosmosChains from "./hooks/useCosmosChains";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
const queryClient = new QueryClient();
function ZWidget({ gradientColor }) {
  const setGradient = useStore((state) => state.setGradient);
  const setConfiguration = useStore((state) => state.setConfiguration);
  const configuration = useStore((state) => state.configuration);
  const theme = useStore((state) => state.theme);
  const setTheme = useStore((state) => state.setTheme);
  const setIntegratorId = useStore((state) => state.setIntegratorId);
  const cosmosChains = useCosmosChains();
  const adapters = useMemo(() => [], []);
  // const { chains, publicClient, webSocketPublicClient } = configureChains(
  //   [
  //     mainnet,
  //     polygonMumbai,
  //     polygon,
  //     optimism,
  //     goerli,
  //     sepolia,
  //     bsc,
  //     zkSync,
  //     base,
  //     arbitrum,
  //     fantom,
  //     avalanche,
  //     polygonZkEvm,
  //     fuse,
  //     optimism,
  //     boba,
  //     okc,
  //     moonriver,
  //     aurora,
  //     linea,
  //     gnosis,
  //     moonbeam,
  //     cronos,
  //     evmos,
  //     telos,
  //     harmonyOne,
  //   ],

  //   [
  //     alchemyProvider({ apiKey: "UPTSl0S8hg5frz84jM4q1xc1pJzjHL86" }),
  //     infuraProvider({ apiKey: "509ce1bf287447d8b4c5c9f1c034b251" }),
  //     publicProvider(),
  //   ]
  // );

  // 3. Initialize TronWeb with proper configuration

  useEffect(() => {
    const { fromChain, fromCoin, toChain, toCoin, backgroundColor } =
      getParams();
    setConfiguration({
      integratorId: "01J7GAVEKWEB8DV087H4N4ZKRH",
      // gradientStyle: {
      //   background:
      //     "linear-gradient(90deg, #833ab4 0%, #fd1dcd 50%, #fcb045 100%)",
      //   spinnerColor: "#833ab4",
      //   stopColor: "#fd1dcd",
      // },

      containerStyle: {},
      // fontFamily: fontFamily ? fontFamily : "",
      theme,
      // headingText: "Blockendlabs",
      // headingContainerStyles: {
      //   minWidth: "155px",
      //   transform: "skewX(0deg)",
      //   left: "0px",
      //   top: "0px",
      // },
      // headingStyles: {
      //   transform: "skewX(0deg)",
      // },

      // gradientStyle: {
      //   background:
      //     "linear-gradient(90deg, #833ab4 0%, #fd1dcd 50%, #fcb045 100%)",
      //   spinnerColor: "#833ab4",
      //   stopColor: "#fd1dcd",
      // },

      // theme: "dark",¯
      // customTheme: {
      // text: {
      //   primary: "#fff",
      //   secondary: "#ffffde",
      //   placeholder: "#fd1dcd",
      //   success: "#49AD71",
      //   error: "#FD5868",
      // },
      // background: {
      //   container: "rgb(15, 15, 15)",
      //   secondary: "#3c3c3c",
      //   card: "rgb(15, 15, 15)",
      //   networkCard: "#3c3c3c",
      //   loaderbar: "#E9E9E9",
      //   coin: "#E0E0E0",
      // rewards: "#833ab4",
      // },
      // border: {
      //   primary: "#9e9e9e",
      //   secondary: "#808080",
      //   inputHighlight: "#9FC966",
      // },
      // fontFamily: "sans-serif",
      // shadow: {
      //   boxShadow: "1px 1px 7px 5px rgb(255,255,255,0.1)",
      // },
      // },
      // },
      defaultChains: {
        from: { chainId: fromChain ? fromChain : "137" },
        to: { chainId: toChain ? toChain : "56" },
      },
      defaultTokens: {
        from: {
          tokenAddress: fromCoin
            ? fromCoin
            : "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        },
        to: {
          tokenAddress: toCoin
            ? toCoin
            : "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        },
      },
    });
  }, [theme]);

  useEffect(() => {
    setColors(configuration);
  }, [configuration]);
  useEffect(() => {
    if (false) {
      throw new Error("Integrator ID is required.");
    } else {
      setIntegratorId("01J7GAVEKWEB8DV087H4N4ZKRH");
    }
  }, []);
  useEffect(() => {
    if (configuration.theme === "dark") {
      // document.documentElement.setAttribute("data-theme", "dark");
      setTheme("dark");
    } else {
      // document.documentElement.classList.remove("dark");
      // document.documentElement.setAttribute("data-theme", "light");
      setTheme("light");
    }
  }, [configuration.theme]);
  useEffect(() => {
    window.Buffer = Buffer;
    if (!isEmpty(configuration)) {
      setConfiguration(configuration);
    }
    setGradient(gradientColor);
  }, [configuration, gradientColor]);
  function onError(error) {
    console.log(error, "error");
  }
  const cosmoshub = {
    chainId: "cosmoshub-4",
    chainName: "Cosmos Hub",
    rpc: "https://cosmos-rpc.publicnode.com:443",
  };
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <WagmiProvider config={config} reconnectOnMount={true}>
        <DynamicContextProvider
          settings={{
            // events: {
            //   onAuthFailure: (method, reason) => {
            //     console.log("onAuthFailure was called", method, reason);
            //   },
            // },

            environmentId: "5d12d5a9-6206-433b-85b8-25fc9f0da5e3",
            walletConnectors: [SolanaWalletConnectors],
            initialAuthenticationMode: "connect-only",
            enableVisitTrackingOnConnectOnly: true,
          }}
        >
          <WalletProvider adapters={adapters} onError={onError}>
            <GrazProvider
              grazOptions={{
                chains: cosmosChains?.length ? [...cosmosChains] : [cosmoshub],
                defaultWalletType: WalletType.KEPLR,
              }}
            >
              <ToggleButton />
              {/* <SendTx /> */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <WidgetContainer />
              </LocalizationProvider>
            </GrazProvider>
          </WalletProvider>
        </DynamicContextProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}

export default ZWidget;
