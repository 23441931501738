import {
  useDynamicContext,
  useWalletItemActions,
  useUserWallets,
  useWalletOptions,
  useIsLoggedIn,
  useSwitchWallet,
} from "@dynamic-labs/sdk-react-core";
import { useEffect, useState } from "react";
import images from "../../images";
import { CircularProgress } from "@mui/material";
import InstalledIcon from "../InstalledIcon";
import useStore from "../../zustand/store";
import useWalletDetails from "../../hooks/useWalletDetails";
import styles from "../SelectWallet/SelectWallet.module.css";
import getSolWalletInstalled from "./getSolWalletInstalled";
import { keyBy } from "lodash";
import DisconnectWallet from "../Svg/disconnectWallet";
const Main = ({
  fromChain,
  toChain,
  connectWalletField,
  handleSolConnecting,
  selectedWalletTab,
  handleShowWallet,
}) => {
  const { primaryWallet, handleLogOut, handleUnlinkWallet } =
    useDynamicContext();
  const isFullyConnected = useIsLoggedIn();
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [walletObj, setWalletObj] = useState();
  const { setShowAuthFlow, walletConnectorOptions } = useDynamicContext();
  const { openWallet } = useWalletItemActions();
  const configuration = useStore((state) => state.configuration);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const { handleSOLDisable } = useWalletDetails({ fromChain, toChain });
  const isSOLDisable = handleSOLDisable(connectWalletField);
  const wallets = useUserWallets();
  const { selectWalletOption, walletOptions } = useWalletOptions();
  const setPrimaryWallet = useSwitchWallet();

  useEffect(() => {
    if (isFullyConnected && isLoading && primaryWallet !== null) {
      setIsLoading(false);
    }
  }, [isFullyConnected, primaryWallet]);
  const {
    phantom,
    solflare,
    slope,
    installedicon,
    magiceden,
    backpacksol,
    keplr,
    solanaicon,
    disconnecticon,
  } = images;
  const walletIcons = {
    solflare,
    phantom,
    slope,
    backpack: backpacksol,
    "magic eden (solana)": magiceden,
    keplr,
  };
  useEffect(() => {
    if (wallets.length) {
      setWalletObj(keyBy(wallets, (item) => item.connector.name));
    } else {
      setWalletObj();
    }
  }, [wallets.length]);
  const handleClick = (item) => {
    if (item.name == primaryWallet?.connector?.name) {
      if (connectWalletField == "to") {
        setToDisconnect(false);
        handleShowWallet();
      } else {
      }
    } else {
      if (walletObj?.[item.name]) {
        setPrimaryWallet(walletObj?.[item?.name]?.id);
        handleShowWallet();
        setToDisconnect(false);
      } else {
        openWallet(item.key?.toLowerCase());
        setIsLoading(true);
        handleSolConnecting(true, item.name?.toLowerCase());
      }
    }
  };
  return walletOptions?.length ? (
    <>
      {walletOptions
        .filter((item, i) => {
          if (
            ((item.name == "Solflare" ||
              item.name == "Slope" ||
              item.name == "Phantom" ||
              item.name == "Backpack" ||
              item.name == "Magic Eden (Solana)") &&
              selectedWalletTab !== "Cosmos") ||
            (item.name == "Keplr" &&
              (selectedWalletTab === "Cosmos" || selectedWalletTab === "All"))
          ) {
            return true;
          }
        })
        .map((item, i) => {
          return (
            <div
              style={
                item.name == primaryWallet?._connector?.name
                  ? {
                      ...configuration?.gradientStyle,
                    }
                  : {}
              }
              className={`bw-w-[47%] bw-mb-4 ${
                item.name == "Keplr" && selectedWalletTab === "Cosmos"
                  ? ""
                  : "  "
              } bw-rounded-md bw-p-[1px] ${
                !configuration.gradientStyle &&
                item.name == primaryWallet?._connector?.name
                  ? styles.gradientbutton
                  : "bw-bg-border-primary"
              } `}
            >
              <div
                className={` bw-relative bw-flex bw-w-full bw-bg-background-container  hover:bw-shadow-sm bw-font-medium  hover:bw-font-bold hover:bw-text-text-primary  bw-items-center bw-gap-x-3  bw-rounded-md  bw-h-[56px] bw-px-3 bw-cursor-pointer  ${
                  ""
                  // isFullyConnected ? "bw-pointer-events-none bw-opacity-50" : ""
                }${
                  item.name == primaryWallet?._connector?.name
                    ? " bw-text-text-primary"
                    : "bw-text-text-secondary"
                }`}
                onClick={() => {
                  if (getSolWalletInstalled(item.name?.toLowerCase()))
                    handleClick(item);
                }}
              >
                {/* {item.isInstalledOnBrowser ? (
                <div className="bw-absolute bw-top-1 bw-right-0">
                  <InstalledIcon
                    startColor={configuration?.gradientStyle?.spinnerColor}
                    stopColor={configuration?.gradientStyle?.stopColor}
                  />
                </div>
              ) : (
                <></>
              )} */}
                {walletObj?.[item?.name] ? (
                  <div className="bw-text-[10px] bw-flex bw-items-center bw-gap-x-1 bw-mt-[-2px] bw-font-medium bw-absolute bw-top-1 bw-right-2">
                    <div
                      style={{ ...configuration?.gradientStyle }}
                      className={`bw-w-[10px] bw-rounded-[50%] ${
                        !configuration?.gradientStyle
                          ? styles.gradientbutton
                          : ""
                      } bw-bg-text-greentext bw-h-[10px]`}
                    ></div>{" "}
                    <p
                      style={{ ...configuration?.gradientStyle }}
                      className={` bw-bg-clip-text bw-text-transparent ${
                        !configuration?.gradientStyle
                          ? styles.gradientbutton
                          : ""
                      } `}
                    >
                      Connected
                    </p>
                  </div>
                ) : getSolWalletInstalled(item.name?.toLowerCase()) ? (
                  <div className="bw-absolute bw-top-[2px] bw-right-1">
                    <p className="bw-text-text-secondary bw-text-xs bw-font-normal">
                      Installed
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {walletObj?.[item?.name] ? (
                  <p
                    onClick={async (e) => {
                      e.stopPropagation();

                      if (wallets.length === 1) {
                        handleLogOut();
                      } else {
                        try {
                          let res = await handleUnlinkWallet(
                            walletObj?.[item?.name]?.id
                          );
                        } catch (Err) {
                          console.log(Err, "err");
                        }
                      }
                    }}
                    className=" bw-cursor-pointer bw-absolute bw-right-[5px] bw-bottom-1 bw-text-[10px] bw-text-text-primary  bw-z-40 bw-rounded-[50%]"
                  >
                    <div className="bw-w-[20px] bw-h-[20px]">
                      <DisconnectWallet fill={"var(--tw-text-primary)"} />
                    </div>
                  </p>
                ) : (
                  <></>
                )}
                <div className="bw-w-max bw-relative">
                  <img
                    src={walletIcons[item.name?.toLowerCase()]}
                    width={32}
                    height={32}
                    className="bw-w-[32px] bw-h-[32px] bw-rounded-md"
                    alt="img"
                  />
                  <img
                    src={solanaicon}
                    className="bw-absolute bw-right-[-15%] bw-bottom-[-30%]"
                    width=""
                    height=""
                    alt="img"
                  />
                </div>
                <p className="bw-text-xs bw-w-max ">{item.name}</p>
              </div>
            </div>
          );
        })}
    </>
  ) : (
    <div className="bw-w-full bw-flex bw-justify-center bw-mt-2 bw-text-text-primary">
      <CircularProgress color="inherit" size={14} />
    </div>
  );
};

export default Main;
