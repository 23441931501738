import React, { useState, useEffect } from "react";
import BroadCastMode from "./BroadCastMode";
import OptionsComp from "./OptionsComp";
import CustomComp from "./CustomComp";
import SaveButton from "./SaveButton";
import useStore from "../../../zustand/store";
import Header from "./Header";
import SlippageComp from "./SlippageComp";
import TransactionFeeHeading from "./TransactionFeeHeading";
import { set } from "lodash";
function SolanaFeeComp({ handleClose }) {
  const [inputVal, setInputVal] = useState("");
  const [options, setOptions] = useState({ name: "Low", value: "low" });
  const [errorMessage, setErrorMessage] = useState("");
  const [mode, setMode] = useState("");
  const [slippage, setSlippage] = useState("");
  const broadCastMode = useStore((state) => state.broadCastMode);
  const customFee = useStore((state) => state.customFee);
  const setCustomFee = useStore((state) => state.setCustomFee);
  const slippageBlobal = useStore((state) => state.slippage);
  const setSlippageGlobal = useStore((state) => state.setSlippage);
  const setBroadCastMode = useStore((state) => state.setBroadCastMode);
  const option = useStore((state) => state.option);
  const setOption = useStore((state) => state.setOption);
  const isSaveButtonDisabled =
    inputVal == customFee &&
    option.name == options.name &&
    slippageBlobal == slippage &&
    ((mode === broadCastMode && mode !== "") ||
      (!inputVal.length && !option.name.length));
  function handleCustomVal(e) {
    setErrorMessage("");
    setInputVal(e.target.value);
  }
  function handleOptions(item, isInputReset) {
    setErrorMessage("");
    // if (isInputReset) {
    //   setInputVal("");
    //   setCustomFee("");
    // }
    setOptions(item);
  }
  useEffect(() => {
    setOptions(option);
  }, [option]);
  useEffect(() => {
    customFee != null && customFee.length
      ? setInputVal(customFee)
      : setInputVal("");
  }, [customFee]);
  function handleSaveOptions() {
    setOption(options);
  }
  useEffect(() => {
    setSlippage(slippageBlobal);
  }, [setSlippageGlobal]);
  function handleSlippage(e) {
    setSlippage(e.target.value);
  }
  useEffect(() => {
    setMode(broadCastMode);
  }, [broadCastMode]);
  function handleSave() {
    if (mode == "" && (inputVal.length || options.name.length)) {
      setBroadCastMode("");
      setErrorMessage("Please select a mode");
      return;
    }
    if (inputVal.length) {
      setCustomFee(inputVal);
      setOption(options);
    } else {
      setCustomFee("");
      handleSaveOptions();
    }
    setBroadCastMode(mode);
    setSlippageGlobal(slippage);
    handleClose();
  }
  function handleMode(mode) {
    setErrorMessage("");
    setMode(mode);
  }
  useEffect(() => {
    console.log(customFee, option.name, "customFee");
    if (!customFee && !option.name.length) {
      setBroadCastMode("");
      setMode("");
    }
  }, [customFee, option.name]);
  console.log(options, option, "customFee");
  return (
    <div className="bw-w-full md:bw-w-[443px] bw-h-[447px] bw-rounded-lg bw-border bw-p-3 px-4 md:bw-px-6 bw-border-border-primary bw-text-white bw-bg-background-container">
      <Header handleClose={handleClose} />
      <div className="bw-mt-5 bw-mb-5">
        <SlippageComp slippage={slippage} handleSlippage={handleSlippage} />
      </div>
      <div className="bw-mb-5">
        <TransactionFeeHeading />
      </div>

      <BroadCastMode mode={mode} handleMode={handleMode} />
      <OptionsComp options={options} handleOptions={handleOptions} />
      <CustomComp
        title="Max Cap"
        inputSideText="SOL"
        inputVal={inputVal}
        handleCustomVal={handleCustomVal}
      />
      <div className="bw-relative">
        <p className="bw-w-full bw-absolute bw-text-center -bw-top-5 bw-text-xs bw-text-text-redtext">
          {errorMessage}
        </p>
        <SaveButton
          handleSave={handleSave}
          isSaveButtonDisabled={isSaveButtonDisabled}
        />
      </div>
    </div>
  );
}

export default SolanaFeeComp;
