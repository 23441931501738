import React from "react";
import { useMutation } from "@tanstack/react-query";
import controllers from "../Actions/Controllers";
import useStore from "../zustand/store";
import useWalletDetails from "./useWalletDetails";
function useRegisterError() {
  const fromChain = useStore((state) => state.fromChain);
  const userId = useStore((state) => state.userId);
  const { getWallet } = useWalletDetails({});
  const { data, isLoading, mutate, mutateAsync, isSuccess } = useMutation({
    mutationKey: "register-error",
    mutationFn: async (data) => {
      let req = await controllers.registerError(data);
      let res = await req.json();
    },

    onSuccess: (data) => {},
    onError: (err) => {
      console.log(err, "errorregistering");
    },
    retry: false,
  });
  async function registerError(data = {}) {
    try {
      await mutateAsync({
        sessionId: userId,
        userWalletAddress: getWallet(fromChain)?.address || "",
        error: data?.error || "",
        ...data,
      });
    } catch (err) {
      console.log(err);
    }
  }
  return {
    registerErrorData: data,
    registerErrorIsLoading: isLoading,
    registerErrorMutate: mutate,
    registerErrorMutateAsync: mutateAsync,
    registerErrorIsSuccess: isSuccess,
    registerError,
  };
}

export default useRegisterError;
