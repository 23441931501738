import TokenBox from "./TokenBox";
import React, { useEffect, useState } from "react";
import useStore from "../../zustand/store";
import TxnTimer from "../TxnTimer";
import Header from "./Header";
import ProviderComp from "./ProviderComp";
import TransactionComp from "./TransactionComp";
import { TxnSuccessContext } from "../Context/txnSuccessContext";
import { TxnErrorContext } from "../Context/txnErrorContext";
import images from "../../images";
import EstGas from "./EstGas";
import QuoteTimer from "./quoteTimer";
const Exchange = React.memo(function ({
  handleOpenExchange,
  amount,
  route,
  resetAmount,
  resetBalance,
  setExchange,
}) {
  //zustand
  const setPersist = useStore((state) => state.setPersist);
  const persist = useStore((state) => state.persist);
  const fromChain = useStore((state) => state.fromChain);
  const fromCoin = useStore((state) => state.fromCoin);
  const toChain = useStore((state) => state.toChain);
  const toCoin = useStore((state) => state.toCoin);

  //states
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [isTxnSuccessful, setIsTxnSuccessful] = useState(false);
  const [isErrorMessage, setIsErrormessage] = useState(false);
  const isLoading = !persist.stepData;
  // custom hooks from txn execution
  const { txnupdate } = images;
  useEffect(() => {
    if (route?.routeId) {
      setPersist({
        ...useStore.getState()?.persist,
        routeId: route?.routeId,
        route,
        toChain,
        toCoin,
        fromChain,
        fromCoin,
        amount,
        quote: "enable",
        openAt: new Date().getTime(),
        startTime:
          useStore?.getState()?.persist?.startTime || new Date().getTime(),
      });
    }
  }, [route]);

  function startTimer(val) {
    setIsTimerStarted(val);
  }

  function handleFocus() {
    if (
      (persist?.openAt + 60 * 60 * 1000 - new Date().getTime()) / 1000 / 60 <=
        0 &&
      document.visibilityState === "visible"
    ) {
      setPersist({});
      handleOpenExchange();
      resetAmount();
    }
  }
  useEffect(() => {
    if (persist?.openAt) {
      window.addEventListener("visibilitychange", handleFocus);
    }
    return () => {
      window.removeEventListener("visibilitychange", handleFocus);
    };
  }, [persist?.openAt]);
  useEffect(() => {
    if (persist?.openAt) {
      window.addEventListener("load", handleFocus);
    }
    return () => {
      window.removeEventListener("load", handleFocus);
    };
  }, [persist?.openAt]);
  function handleTxnSuccess(val) {
    setIsTxnSuccessful(val);
  }
  function handleErrorMessage(val) {
    setIsErrormessage(val);
  }
  function handleBack() {
    handleOpenExchange();
    // resetAmount();
    setPersist({});
    if (isTxnSuccessful) {
      resetBalance();
    }
  }
  return (
    <TxnSuccessContext.Provider value={{ isTxnSuccessful, handleTxnSuccess }}>
      <TxnErrorContext.Provider value={{ isErrorMessage, handleErrorMessage }}>
        <div className="bw-w-full bw-relative bw-min-h-[610px] ">
          <Header handleBack={handleBack} />
          <div className="bw-mt-[-20px] ">
            <ProviderComp />
          </div>
          {persist?.isQuoteTimer ? <QuoteTimer /> : <></>}
          <div className="bw-flex bw-justify-between bw-items-center"></div>
          <div className="bw-flex bw-w-full bw-mt-4 bw-justify-between bw-items-center">
            <div className="bw-w-[40%] md:bw-w-auto">
              <p className="bw-text-xs bw-mb-1 bw-text-text-secondary bw-font-medium">
                Send
              </p>
              <TokenBox
                amount={persist?.amount}
                coinData={persist.fromCoin}
                chainData={persist.fromChain}
                field="from"
              />
            </div>
            {isErrorMessage ? (
              <div
                className={`bw-border bw-mt-5 bw-w-[65px] bw-h-[65px] bw-flex bw-items-center bw-justify-center bw-rounded-[50%] bw-bg-background-card  bw-relative bw-z-10  hover:bw-opacity-100  bw-border-border-primary `}
              >
                <img src={txnupdate} width={33} height={30} alt="img" />
              </div>
            ) : (
              <TxnTimer
                isTimerStarted={persist?.isTimerStarted || isTimerStarted}
                startTimer={startTimer}
                error={isErrorMessage}
              />
            )}

            <div className="bw-w-full bw-z-0 md:bw-w-full bw-mt-5  bw-absolute bw-h-[1px] bw-bg-border-primary "></div>
            <div className="bw-w-[40%] md:bw-w-auto">
              <p className="bw-text-xs bw-mb-1 bw-text-text-secondary bw-font-medium">
                Receive
              </p>
              <TokenBox
                amount={Number(persist?.route?.outputAmountDisplay || "")}
                coinData={persist?.toCoin}
                chainData={persist?.toChain}
                field="to"
              />
            </div>
          </div>
          <div className="bw-h-[320px] bw-pr-1 bw-flex bw-flex-col bw-mt-8 bw-overflow-y-auto">
            {isLoading ? <></> : <EstGas />}
            <TransactionComp
              handleOpenExchange={handleOpenExchange}
              setExchange={setExchange}
              amount={amount}
              startTimer={startTimer}
              handleBack={handleBack}
              isTimerStarted={persist?.isTimerStarted || isTimerStarted}
            />
          </div>
        </div>
      </TxnErrorContext.Provider>
    </TxnSuccessContext.Provider>
  );
});

export default Exchange;
