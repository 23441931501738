import React, { useState, useEffect } from "react";
import TransactionTab from "./TransactionTab";
import TransactionList from "./TransactionList";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import controllers from "../../Actions/Controllers";
import useStore from "../../zustand/store";
import { useQueryClient } from "@tanstack/react-query";
import useWalletDetails from "../../hooks/useWalletDetails";
import useRegisterError from "../../hooks/useRegisterError";
import { useAccount } from "wagmi";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useAccount as useCosmosAccount } from "graz";
function TransactionData() {
  const [value, setValue] = useState("all");
  function handleValue(val) {
    setValue(val);
  }
  const queryClient = useQueryClient();
  const fromChain = useStore((state) => state.fromChain);
  const cosmosChainIds = useStore((state) => state.cosmosChainIds);
  const { getWallet, getAllConnections } = useWalletDetails({
    chainData: fromChain,
  });
  const { data: cosmosData } = useCosmosAccount({
    chainId: cosmosChainIds,
    multiChain: true,
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { address } = useAccount();

  const { primaryWallet } = useDynamicContext();
  // const fromAddress =
  //   getWallet(fromChain)?.address ||
  //   address ||
  //   primaryWallet?.address ||
  //   cosmosData?.["cosmoshub-4"]?.bech32Address ||
  //   "";
  const addresses = getAllConnections();
  const { registerError } = useRegisterError();
  const historyData = useInfiniteQuery({
    queryKey: ["history", addresses, value, startDate, endDate],
    queryFn: async ({ pageParam = 1 }) => {
      let req = await controllers.fetchHistory(
        addresses,
        value === "all" || value == "date" ? "" : value,
        pageParam,
        startDate,
        endDate
      );
      return await req.json();
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
      if (allPages.length < allPages[0]?.pageCount) {
        return allPages.length + 1;
      } else {
        return null;
      }
    },
    getPreviousPageParam: (lastPage, allPages) => {
      if (allPages.length < allPages[0]?.pageCount) {
        return allPages.length - 1;
      } else {
        return false;
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!addresses.length,
    onSuccess: (data) => {
      // if (data.status == "error") {
      //   registerError({ module: "/history", error: data.error });
      // }
    },
    onError: (err) => {
      // let error = err.details || err.message || "";
      // registerError({ module: "/history", error });
    },
  });
  function handleDate(start, end) {
    setStartDate(start ? new Date(start).getTime() : "");
    setEndDate(end ? new Date(end).getTime() : "");
    start && end && setValue("date");
  }
  useEffect(() => {
    queryClient.setQueryData(["history"], (existingData) => {
      return existingData
        ? {
            pageParams: [existingData.pageParams[0]],
            pages: [existingData.pages[0]],
          }
        : undefined;
    });
  }, [value]);

  return (
    <div className="bw-mt-4">
      <TransactionTab
        value={value}
        handleValue={handleValue}
        handleDate={handleDate}
      />
      <TransactionList
        value={value}
        historyData={historyData}
        addresses={addresses}
      />
    </div>
  );
}

export default TransactionData;
