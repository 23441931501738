import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { VersionedTransaction } from "@solana/web3.js";
import React, { useState } from "react";
import { useAccount } from "wagmi";
import { base64, base58 } from "ethers/lib/utils";
import { ISolana } from "@dynamic-labs/solana";

export default function useSolanaTxn() {
  const { primaryWallet } = useDynamicContext();
  const { address } = useAccount();
  const [solError, setSolError] = useState(false);
  const [solData, setSolData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  function resetSolData() {
    setSolError(false);
    setErrorMessage("");
    setSolData(null);
  }
  const handleTxn = async (amount, walletType, txn) => {
    // console.log(txn, "txn");
    setSolError(false);
    setErrorMessage("");
    const transactionRequest = txn?.txnData?.txnSol?.data;

    const decodedTx = base64.decode(transactionRequest); // decode tx data

    const deserializedTx = VersionedTransaction.deserialize(decodedTx); // deserialize decoded tx data into VersionedTransaction

    if (!primaryWallet) return;
    // if (
    //   deserializedTx.message.recentBlockhash ==
    //   "11111111111111111111111111111111"
    // ) {
    //   const blockhash = await fetch(
    //     "https://solstation.blockend.com/api/blockhash"
    //   ).then((res) => res.json());

    //   deserializedTx.message.recentBlockhash = blockhash.blockhash.blockhash;
    // }
    const connection = await primaryWallet.connector.getPublicClient();
    if (!connection) return;
    try {
      const blockhash = await connection.getLatestBlockhash();
      deserializedTx.message.recentBlockhash = blockhash.blockhash;

      // console.log(deserializedTx, "desrializedTx");
    } catch (err) {
      console.log(err);
    }

    if (txn?.txnData?.txnSol?.data) {
      const signer = await primaryWallet.connector.getSigner();

      if (!signer) return;

      try {
        let sendResp = await signer.signAndSendTransaction(deserializedTx, {
          skipPreflight: false,
        });
        setSolError(false);
        setSolData(sendResp);
      } catch (err) {
        console.log("send ERR", err);
        setSolError(true);
        setErrorMessage(err);
      }
    }
  };
  return {
    solData,
    solErrMessage: errorMessage,
    solError,
    handleTxn,
    resetSolData,
  };
}
