import React from "react";
import useStore from "../../zustand/store";
function TxnStatusComp({ txnTextObj, txnProcessType, errorText, allSteps }) {
  const persist = useStore((state) => state.persist);
  return (
    <>
      <p className="bw-text-lg bw-font-medium bw-mb-1 bw-text-text-primary">
        {persist?.stepStatus && persist?.stepStatus[allSteps?.currentStep]
          ? txnTextObj?.status?.[
              persist?.stepStatus[allSteps?.currentStep]?.status
            ] || txnTextObj?.[persist?.stepStatus[allSteps?.currentStep]?.type]
          : ""}
      </p>
      <p className="bw-text-sm bw-text-center bw-font-medium bw-mb-4 bw-text-text-secondary">
        {
          txnTextObj?.[
            txnProcessType == "process"
              ? "processingDescription"
              : txnProcessType == "loading"
              ? "loadingDescription"
              : "preDescription"
          ]
        }
      </p>
      <p className="bw-text-xs bw-font-normal bw-text-center bw-text-text-redtext ">
        {errorText}
      </p>
    </>
  );
}

export default TxnStatusComp;
