import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { Connection, PublicKey } from "@solana/web3.js";
import { useEffect, useState } from "react";
export default function useSolBalance(
  fromChain,
  isTransactionSuccessful,
  handleSuccess
) {
  const { primaryWallet } = useDynamicContext();
  const [balance, setBalance] = useState(0);
  const getBalance = async () => {
    if (!primaryWallet) return;
    const connection = await primaryWallet.connector.getPublicClient();
    if (!connection) return;

    try {
      const publicKey = new PublicKey(primaryWallet.address);
      connection.onAccountChange(publicKey, (e) => {
        setBalance(e.lamports / 1000000000);
      });
      const balanceSol = await connection.getBalance(publicKey);

      // console.log("Balance in lamports:", balance);
      const balanceSolVal = balanceSol / 1000000000; // Convert lamports to SOL
      setBalance(balanceSolVal);
      handleSuccess(false);
    } catch (err) {
      console.log(err);
      handleSuccess(false);
    }
  };
  useEffect(() => {
    fromChain?.networkType === "sol" && getBalance();
  }, [fromChain?.networkType, primaryWallet?.address]);
  return { balanceSol: balance, getBalanceSol: getBalance };
}
