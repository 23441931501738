import React from "react";

function CreateTxError({ fill, stroke }) {
  return (
    <svg
      width="113"
      height="62"
      viewBox="0 0 113 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8.4375"
        y="24.9023"
        width="78.9692"
        height="36.4567"
        rx="9.5"
        fill={fill}
        stroke={stroke}
      />
      <rect
        x="15.5742"
        y="32.5957"
        width="42.7954"
        height="14.5366"
        rx="4"
        fill={stroke}
      />
      <rect
        x="38.8398"
        y="50.0684"
        width="42.7954"
        height="6.99623"
        rx="3.49811"
        fill={stroke}
      />
      <rect
        x="1.25"
        y="19.043"
        width="15.3647"
        height="15.2004"
        rx="4.5"
        fill={fill}
        stroke={stroke}
      />
      <g filter="url(#filter0_d_8010_16559)">
        <path
          d="M87.8168 13.1918L101.18 36.3366C102.795 39.134 100.776 42.6307 97.5456 42.6307H70.8202C67.5901 42.6307 65.5713 39.134 67.1864 36.3366L80.549 13.1918C82.1641 10.3944 86.2018 10.3944 87.8168 13.1918Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M85.6358 19.2969V26.2041C85.6358 26.9367 85.5995 27.6572 85.5271 28.3656C85.4546 29.066 85.362 29.8107 85.2493 30.5996H83.2206C83.1079 29.8107 83.0154 29.066 82.9429 28.3656C82.8705 27.6572 82.8342 26.9367 82.8342 26.2041V19.2969H85.6358ZM82.3633 35.1159C82.3633 34.8663 82.4076 34.6288 82.4961 34.4034C82.5927 34.178 82.7215 33.9848 82.8825 33.8238C83.0516 33.6628 83.2488 33.534 83.4742 33.4374C83.6996 33.3408 83.9412 33.2925 84.1988 33.2925C84.4483 33.2925 84.6858 33.3408 84.9112 33.4374C85.1366 33.534 85.3298 33.6628 85.4909 33.8238C85.6599 33.9848 85.7927 34.178 85.8893 34.4034C85.986 34.6288 86.0343 34.8663 86.0343 35.1159C86.0343 35.3735 85.986 35.615 85.8893 35.8404C85.7927 36.0578 85.6599 36.247 85.4909 36.408C85.3298 36.569 85.1366 36.6938 84.9112 36.7823C84.6858 36.8789 84.4483 36.9272 84.1988 36.9272C83.9412 36.9272 83.6996 36.8789 83.4742 36.7823C83.2488 36.6938 83.0516 36.569 82.8825 36.408C82.7215 36.247 82.5927 36.0578 82.4961 35.8404C82.4076 35.615 82.3633 35.3735 82.3633 35.1159Z"
          fill={stroke}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8010_16559"
          x="56.1172"
          y="0.59375"
          width="56.1328"
          height="52.5371"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8010_16559"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8010_16559"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default CreateTxError;
