import React from "react";
import { getWallet, WalletType } from "graz";
async function getChainIds() {
  const keplr = getWallet(WalletType.KEPLR);
  let getChains = await keplr.getChainInfosWithoutEndpoints();
  let chainIds = [];
  const unSupportedChains = [
    "crypto-org-chain-mainnet-1",
    "bostrom",
    "phoenix-1",
    "kyve-1",
    "passage-2",
    "wormchain",
    "seda-1",
    "dimension_37-1",
    "pryzm-1",
    "zetachain_7000-1",
    "eip155:1",
    "eip155:8453",
    "eip155:10",
    "eip155:42161",
    "eip155:137",
    "eip155:56",
    "eip155:43114",
    "eip155:81457",
    "mantra-1",
    "theta-testnet-001",
    "evmos_9001-2",
    "eip155:984122",
    "eip155:253368190",
    "xion-mainnet-1",
    "thorchain-1",
    "elys-1",
  ];
  getChains.forEach((item) => {
    if (unSupportedChains.includes(item?.chainId)) {
    } else {
      chainIds.push(item?.chainId);
    }
  });
  return chainIds;
}

export default getChainIds;
