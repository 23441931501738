import React, { useContext, useEffect } from "react";
import styles from "./Exchange.module.css";
import useStore from "../../zustand/store";
import { TxnErrorContext } from "../Context/txnErrorContext";
import TxnErrorComp from "./TxnErrorComp";
function TransactionFailedCTA({
  handleGoBack,
  errorText,
  txnTextObj,
  handleExeTxn,
  handleDisable,
}) {
  const configuration = useStore((state) => state.configuration);
  const persist = useStore((state) => state.persist);
  return (
    <div className="bw-absolute bw-bg-background-container bw-border-t bw-border-border-primary bw-pt-3 bw-w-full bw-bottom-0 bw-flex bw-flex-col bw-justify-center bw-items-center">
      <TxnErrorComp errorText={errorText} />
      {!persist.isHistory ? (
        <div className="bw-w-full bw-flex bw-items-center bw-gap-x-2">
          <button
            style={{
              boxShadow:
                (configuration &&
                  configuration.customTheme &&
                  configuration.customTheme?.shadow?.boxShadow) ||
                "1px 1px 7px rgba(0, 0, 0, 0.15)",
            }}
            className="bw-border bw-text-lg bw-flex bw-justify-center bw-items-center bw-gap-x-2 disabled:bw-opacity-60 bw-font-medium   bw-h-[50px] bw-w-[50%] bw-bg-background-container  bw-border-border-primary"
            onClick={() => {
              handleGoBack();
            }}
          >
            <p
              style={{
                ...configuration.gradientStyle,
                backgroundClip: "text",
              }}
              className={` ${styles.gradienttext} bw-text-2xl bw-font-normal`}
            >
              Home
            </p>
          </button>
          <button
            style={{
              boxShadow:
                (configuration &&
                  configuration.customTheme &&
                  configuration.customTheme?.shadow?.boxShadow) ||
                "1px 1px 7px rgba(0, 0, 0, 0.15)",
            }}
            className={` bw-text-lg bw-flex bw-justify-center bw-items-center bw-gap-x-2 disabled:bw-opacity-60 bw-font-medium   bw-h-[50px] bw-w-[50%]  ${styles.gradientbutton} bw-rounded-sm bw-p-[3px] `}
            onClick={() => {
              handleExeTxn();
              handleDisable(true);
            }}
          >
            <div className=" bw-bg-background-container bw-w-full bw-h-full bw-flex bw-items-center bw-justify-center">
              <p
                style={{
                  ...configuration.gradientStyle,
                  backgroundClip: "text",
                }}
                className={` bw-text-text-primary bw-text-2xl bw-font-bold`}
              >
                Try Again
              </p>
            </div>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default TransactionFailedCTA;
