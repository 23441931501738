import { useState, useEffect } from "react";
import useStore from "../zustand/store";
import { isEmpty } from "lodash";
import useWalletDetails from "./useWalletDetails";
import { useAccount } from "wagmi";
let blurDate;
let timePauseVal;
let interval;
export default function useQuoteTimer({
  confirmRoute,
  setConfirmRoute,
  routes,
  routesData,
  isRoutesEnabled,
  fetchBalance,
}) {
  const [quoteTimer, setQuoteTimer] = useState(59);
  const { getWallet } = useWalletDetails({});
  const fromChain = useStore((state) => state.fromChain);
  const fromWalletAddress = getWallet(fromChain);
  const { isConnected } = useAccount();
  useEffect(() => {
    if (confirmRoute) {
      clearInterval(interval);
    }
  }, [confirmRoute]);
  useEffect(() => {
    if (
      quoteTimer === 0 &&
      document.visibilityState === "visible" &&
      !confirmRoute &&
      isRoutesEnabled
    ) {
      if (fromWalletAddress.address?.length) fetchBalance.refetch();
      routes.refetch();
    }
  }, [quoteTimer, confirmRoute, isRoutesEnabled]);
  useEffect(() => {
    // setQuoteTimer(59);
  }, [routes.isSuccess]);
  useEffect(() => {
    if (!isEmpty(routesData)) {
      // if (quoteTimer == 0) setQuoteTimer(59);
      document.addEventListener("visibilitychange", handleVisible);
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisible);
    };
  }, [routesData]);
  function handleIntervalBlur() {
    blurDate = Date.now();
    setQuoteTimer((prev) => {
      timePauseVal = prev;
      return prev;
    });
  }
  function handleIntervalFocus() {
    let focusDate = Date.now();
    let diffInSec = Math.round((focusDate - blurDate) / 1000);
    if (60 - timePauseVal + diffInSec > 60) {
      setConfirmRoute((prev) => {
        if (!prev) {
          if (fromWalletAddress.address?.length) fetchBalance.refetch();
          routes.refetch();
          setQuoteTimer(59);
        }
        return prev;
      });
    } else {
      if (timePauseVal && diffInSec) setQuoteTimer(timePauseVal - diffInSec);
    }
  }
  function handleVisible() {
    if (document.visibilityState === "visible") {
      handleIntervalFocus();
    } else {
      handleIntervalBlur();
    }
  }
  useEffect(() => {
    if (isEmpty(routesData)) {
      setQuoteTimer(59);
      if (interval) clearInterval(interval);
    } else {
      interval = setInterval(() => {
        setQuoteTimer((prev) => {
          if (prev == 0) {
            return 59;
          } else {
            return prev - 1;
          }
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [routesData]);
  const timerValue = useStore((state) => state.timerValue);
  return { quoteTimer, setQuoteTimer };
}
