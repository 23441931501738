import React from "react";
import useStore from "../../zustand/store";
import styles from "./Exchange.module.css";
import TxnErrorComp from "./TxnErrorComp";
function HomeButton({ handleBack, errorText }) {
  const configuration = useStore((state) => state.configuration);
  return (
    <>
      <TxnErrorComp errorText={errorText} />
      <button
        onClick={handleBack}
        style={{ ...configuration?.gradientStyle }}
        className={`bw-w-full ${styles.gradientbutton} bw-rounded-sm bw-h-[50px] bw-p-[3px]  bw-border-0 bw-relative bw-z-0  disabled:bw-opacity-50  bw-text-2xl bw-font-bold bw-text-text-primary`}
      >
        <div
          onClick={handleBack}
          className="bw-rounded-sm bw-bg-background-container bw-flex bw-items-center bw-justify-center b-w-full bw-h-full"
        >
          Start A New Transaction
        </div>
      </button>
    </>
  );
}

export default HomeButton;
