/* global BigInt */
import {
  GasPrice,
  calculateFee,
  createIbcAminoConverters,
  AminoTypes,
} from "@cosmjs/stargate";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { toUtf8 } from "@cosmjs/encoding";
import {
  SigningCosmWasmClient,
  createWasmAminoConverters,
} from "@cosmjs/cosmwasm-stargate";
import Long from "long";
import { MsgDepositForBurn } from "./MsgDepositForBurn";
const IBC_TRANSFER_TYPE = "/ibc.applications.transfer.v1.MsgTransfer";
const WASM_TYPE = "/cosmwasm.wasm.v1.MsgExecuteContract";
const CCTP_TYPE = "/circle.cctp.v1.MsgDepositForBurn";

function getAminoTypeConverters() {
  return new AminoTypes({
    ...createIbcAminoConverters(),
    ...createWasmAminoConverters(),
  });
}
function getTimeoutTimestamp() {
  const PACKET_LIFETIME_NANOS = 3600 * 1_000_000_000; // 1 Hour

  const currentTimeNanos = Math.floor(Date.now() * 1_000_000);
  const timeoutTimestamp = Long.fromNumber(
    currentTimeNanos + PACKET_LIFETIME_NANOS
  );
  return timeoutTimestamp.toNumber();
}
async function validateBalance({ data, params }) {
  const { signerAddress } = data;
  const signer = data.signer;
  console.log(params, "params");

  // Convert decimal amount to integer by removing decimal places

  const coin = {
    denom: params.fromToken.address,
    amount: params.fromAmount,
  };
  console.log(coin, "coin");
  if (!signerAddress) {
    throw new Error("signerAddress not provided");
  }

  const signerCoinBalance = await signer.getBalance(signerAddress, coin.denom);
  console.log(signerCoinBalance, "signerCoinBalance");
  const currentBalance = signerCoinBalance.amount;
  const transferAmount = coin.amount;
  console.log(currentBalance, transferAmount, "currentBalance");

  if (Number(transferAmount) > Number(currentBalance)) {
    throw `Insufficient funds for account: ${signerAddress} on chain ${params.fromChain.chainId}`;
  }

  return true;
}
async function executeRouteCosmos(
  signer,
  signerAddress,
  route,
  useBroadcast = true,
  fromToken
) {
  console.log(signer, signerAddress, route, fromToken, "cospar");
  await validateBalance({
    data: { signer, signerAddress },
    params: {
      fromToken: { address: fromToken.from.address },
      fromAmount: fromToken.inputAmount,
      fromChain: { chainId: fromToken.from.chainId },
    },
  });

  const transactionRequest = route.transactionRequest;

  const msgs = [];

  const cosmosMsg = JSON.parse(transactionRequest?.data);

  switch (cosmosMsg.typeUrl) {
    case CCTP_TYPE: {
      signer.registry.register(CCTP_TYPE, MsgDepositForBurn);

      cosmosMsg.value.mintRecipient = new Uint8Array(
        Buffer.from(cosmosMsg.value.mintRecipient, "base64")
      );
      msgs.push(cosmosMsg);

      break;
    }

    case IBC_TRANSFER_TYPE:
      cosmosMsg.value.timeoutTimestamp = Long.fromValue(
        cosmosMsg.value.timeoutTimestamp
      ).toNumber();
      msgs.push(cosmosMsg);

      break;

    case WASM_TYPE:
      // register execute wasm msg type for signer
      signer.registry.register(WASM_TYPE, MsgExecuteContract);

      cosmosMsg.value.msg = toUtf8(cosmosMsg.value.msg);
      msgs.push(cosmosMsg);

      break;

    default:
      throw new Error(`Cosmos message ${cosmosMsg.typeUrl} not supported`);
  }
  console.log(transactionRequest, msgs, "msgs");
  let memo = "";
  if (transactionRequest?.requestId) {
    memo = JSON.stringify({
      squidRequestId: transactionRequest?.requestId,
    });
  }

  // simulate tx to estimate gas cost
  const estimatedGas = await signer.simulate(signerAddress, msgs, memo);
  const gasMultiplier = Number(transactionRequest?.maxFeePerGas) || 1.5;
  const gasPrice = transactionRequest?.gasPrice;

  let tx = signer.signAndBroadcast(
    signerAddress,
    msgs,
    calculateFee(
      Math.trunc(estimatedGas * gasMultiplier),
      GasPrice.fromString(gasPrice)
    ),
    memo
  );

  return tx;
}

export default executeRouteCosmos;
