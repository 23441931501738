import { Connection, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

async function getSolBalancesSpl(walletAddress) {
  // Connect to the Solana devnet (or mainnet-beta for live network)
  if (!walletAddress) return;
  const connection = new Connection(
    "https://tammi-n3hltb-fast-mainnet.helius-rpc.com/"
  );

  // Create a PublicKey object from the wallet address string
  const pubKey = new PublicKey(walletAddress);

  // Get all token accounts for this wallet
  const tokenAccounts = await connection.getParsedTokenAccountsByOwner(pubKey, {
    programId: TOKEN_PROGRAM_ID,
  });

  // Fetch and display balances
  let balanceObj = {};
  for (const { account } of tokenAccounts.value) {
    const mintAddress = account.data.parsed.info.mint;
    const balance = account.data.parsed.info.tokenAmount.uiAmount;
    balanceObj[mintAddress] = balance;
  }
  return balanceObj;
}

export default getSolBalancesSpl;
