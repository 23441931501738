import { useQuery } from "@tanstack/react-query";
import TxnItem from "./TxnItem";
import controllers from "../../Actions/Controllers";
import { keyBy } from "lodash";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import spinner from "../../lottie/spinner.json";
import { useRef } from "react";
import EmptyState from "./EmptyState";
import ConnectWalletComp from "./ConnectWalletComp";
export default function TransactionList({ historyData, value, addresses }) {
  const [chainData, setChainData] = useState({});
  const scrollRef = useRef(null);
  const chains = useQuery({
    queryKey: ["chains"],
    queryFn: async () => {
      let res = await controllers.fetchChain();
      return await res.json();
    },

    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (chains?.data?.data) {
      setChainData({ ...keyBy(chains.data.data, "chainId") });
    }
  }, [chains.data]);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [value]);
  return (
    <div ref={scrollRef} className="bw-w-full bw-h-[400px] bw-overflow-y-auto">
      {addresses.length ? (
        !historyData.isLoading ? (
          historyData.data?.pages?.map((data, i, arr) => {
            if (data.data.length) {
              return data?.data?.map((item, idx, array) => (
                <TxnItem
                  item={item}
                  key={idx}
                  chainData={chainData}
                  historyData={historyData}
                  array={array}
                  i={idx}
                />
              ));
            } else {
              return <EmptyState value={value} />;
            }
          })
        ) : (
          <div className="bw-w-[36px] bw-h-[36px] bw-w-full bw-mt-6 bw-flex bw-justify-center">
            <Lottie animationData={spinner} loop={true} />
          </div>
        )
      ) : (
        <ConnectWalletComp />
      )}
    </div>
  );
}
