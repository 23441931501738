import React, { useEffect, useState } from "react";
import images from "../../images";
import DisconnectIcon from "../Svg/disconnecticon";
import useStore from "../../zustand/store";
import { CircularProgress } from "@mui/material";
import { useAccount, useConnections, useConnect } from "wagmi";
import { keyBy } from "lodash";
import config from "../../service/wagmiConfig";
import WalletIcon from "../Svg/walletIcon";
export default function WalletDetails({
  walletDetails,
  handleToDisconnect,
  show,
  networkType,
  handleShowWallet,
  setConnectWalletField,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { address, chain, data, disconnect, ...rest } = walletDetails;
  const open = Boolean(anchorEl);
  const [connectorObj, setConnectorObj] = useState();
  const { connector } = useAccount();
  const { connectors } = useConnect();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const connections = useConnections();
  useEffect(() => {
    if (connectors.length) {
      setConnectorObj(
        keyBy(connectors, (item) => {
          return item.uid;
        })
      );
    }
  }, [connectors]);
  async function handleDisconnect() {
    if (
      networkType === "evm" &&
      chain?.network !== "custom" &&
      connectorObj?.[connector?.uid]
    ) {
      handleShowWallet();
    } else {
      handleShowWallet();
    }
  }
  return (
    <>
      <button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        id="basic-button"
        style={{ fontSize: "12px" }}
        className="bw-bg-background-container bw-flex bw-items-center bw-justify-center bw-gap-x-2 bw-mb-[4px] bw-w-[100px] bw-h-[25px] bw-rounded-xl bw-border bw-border-border-primary"
      >
        {address ? (
          <>
            <p className="bw-text-text-primary bw-text-xs bw-font-normal">
              {(address?.substring(0, 4) || "") +
                "..." +
                (address?.substring(address.length - 4, address.length) || "")}
            </p>
            <div
              onClick={() => {
                setConnectWalletField(show);
                if (show == "to") {
                  handleDisconnect();
                  handleToDisconnect(true);
                } else {
                  handleDisconnect();
                }
              }}
            >
              {/* <img src={disconnecticon} /> */}
              <WalletIcon fill={"var(--tw-text-secondary)"} />
            </div>
          </>
        ) : (
          <div className="bw-w-[10px] bw-flex bw-text-text-secondary bw-justify-center bw-items-center bw-h-[10px]">
            <CircularProgress size={10} thickness={5} color="inherit" />
          </div>
        )}
      </button>
    </>
  );
}
