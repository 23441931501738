import React from "react";
import HeadingText from "./HeadingText";
import useStore from "../../zustand/store";

function HeadingContainer() {
  const configuration = useStore((state) => state.configuration);
  const customStyles = configuration?.headingContainerStyles;
  return (
    <div
      style={{
        transform: "skewX(-20deg)",
        transformOrigin: "right",
        // minWidth: "165px",
        borderRadius: "0 0 10px 0",
        border: "1px solid var(--tw-border-primary)",
        borderTop: "none",
        borderLeft: "none",
        height: "37px",
        left: "6px",
        top: "0px",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: "bold",
        color: "var(--tw-text-primary)",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5rem",
        paddingRight: "15px",
        paddingLeft: "10px",
        ...customStyles,
      }}
    >
      <HeadingText />
    </div>
  );
}

export default HeadingContainer;
