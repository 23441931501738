import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import useWalletDetails from "../../hooks/useWalletDetails";
import images from "../../images";
import InstalledIcon from "../InstalledIcon";
import useStore from "../../zustand/store";
import styles from "../SelectWallet/SelectWallet.module.css";
export default function TronWallet({
  handleTronConnection,
  connectWalletField,
  fromChain,
  toChain,
  handleShowWallet,
}) {
  const {
    wallets,
    select,
    connected,
    address,
    connect,
    wallet: tronWallet,
    disconnect,
  } = useWallet();
  const { handleTRONDisable } = useWalletDetails({ fromChain, toChain });
  const isTRONDisable = handleTRONDisable(connectWalletField);
  const configuration = useStore((state) => state.configuration);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  function handleConnect(wallet) {
    if (wallet.state === "Connected" && tronWallet !== null) {
      if (connectWalletField === "to") {
        handleShowWallet();
        setToDisconnect(false);
      } else {
        disconnect();
      }
    } else {
      try {
        if (tronWallet) {
          connect();
        } else {
          select(wallet.adapter.name);
        }
      } catch (err) {
        console.log(err);
      }
      handleTronConnection(true, wallet.adapter?.name);
    }
  }
  const { tronLink } = images;
  const walletIcons = {
    tronlink: tronLink,
  };
  return wallets.map((wallet, i) => (
    <div
      style={
        wallet.state === "Connected" && tronWallet !== null
          ? {
              ...configuration?.gradientStyle,
            }
          : {}
      }
      className={`bw-w-[47%] bw-mb-4 bw-ml-0   bw-rounded-md bw-p-[1px] ${
        !configuration.gradientStyle &&
        wallet.state === "Connected" &&
        tronWallet !== null
          ? styles.gradientbutton
          : "bw-bg-border-primary"
      } `}
    >
      <div
        onClick={() => handleConnect(wallet)}
        style={{ cursor: "pointer" }}
        className={`bw-w-full bw-bg-background-container   bw-font-medium   hover:bw-font-bold hover:bw-text-text-primary hover:bw-shadow-sm  bw-flex bw-gap-x-3 bw-px-3 bw-justify-start bw-items-center bw-h-[56px]
       ${
         ""
         // selectedWalletTab == "All"
         //   ? "bw-absolute bw-bottom-[8%] bw-right-3"
         //   : "bw-relative"
       }
        bw-border bw-relative bw-rounded-md bw-border-border-primary ${
          ""
          // connected ? "bw-opacity-50 bw-pointer-events-none" : ""
        } ${
          wallet.state === "Connected" && tronWallet !== null
            ? " bw-text-text-primary"
            : "bw-text-text-secondary"
        } `}
        key={i}
      >
        {walletIcons[wallet?.adapter?.name?.toLowerCase()] ? (
          <img
            src={walletIcons[wallet.adapter.name.toLowerCase()]}
            width={40}
            height={40}
            className="bw-max-h-[40px] bw-rounded-[50%]"
            alt="img"
          />
        ) : (
          <></>
        )}
        <p className="bw-text-xs bw-w-max ">{wallet.adapter.name}</p>
        {/* {wallet.adapter._readyState?.toLowerCase() === "found" ? (
        <div className="bw-absolute bw-top-1 bw-right-2">
          <InstalledIcon
            startColor={configuration?.gradientStyle?.spinnerColor}
            stopColor={configuration?.gradientStyle?.stopColor}
          />
        </div>
      ) : (
        <></>
      )} */}
        {wallet.state === "Connected" && tronWallet !== null ? (
          <div className="bw-text-[10px] bw-flex bw-items-center bw-gap-x-1 bw-mt-[-2px] bw-font-medium bw-absolute bw-top-1 bw-right-2">
            <div className="bw-w-[10px] bw-rounded-[50%] bw-bg-text-greentext bw-h-[10px]"></div>{" "}
            Connected
          </div>
        ) : wallet.adapter._readyState?.toLowerCase() === "found" ? (
          <div className="bw-absolute bw-top-1 bw-right-0">
            <InstalledIcon
              startColor={configuration?.gradientStyle?.spinnerColor}
              stopColor={configuration?.gradientStyle?.stopColor}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  ));
}
