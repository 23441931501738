import React, { useEffect, useState } from "react";
import { useAccount } from "graz";
import { useStargateClient } from "graz";
import { keyBy } from "lodash";
import useStore from "../zustand/store";
function useCosmosBalance(selectedChain) {
  const [balance, setBalance] = useState({});
  const cosmosChainIds = useStore((state) => state.cosmosChainIds);
  const { data } = useAccount({
    chainId: cosmosChainIds,
    multiChain: true,
  });
  const fromChain = useStore((state) => state.fromChain);
  const { data: client } = useStargateClient({
    chainId: [selectedChain?.chainId || fromChain.chainId],
    multiChain: true,
  });

  async function getBal(chainId) {
    if (data?.[chainId]?.bech32Address && client) {
      let parsedClient = client[chainId] || client;
      let balance = await parsedClient.getAllBalances(
        data?.[chainId]?.bech32Address
      );
      let balObj = keyBy(balance, "denom");
      setBalance(balObj);
    }
  }
  useEffect(() => {
    if (
      selectedChain?.networkType == "cosmos" ||
      fromChain?.networkType == "cosmos"
    )
      getBal(selectedChain?.chainId || fromChain?.chainId);
  }, [client, fromChain, selectedChain]);
  return { cosmosBalance: balance };
}

export default useCosmosBalance;
