import React, { useEffect } from "react";
import styles from "./Exchange.module.css";
import useStore from "../../zustand/store";
import useRoutes from "../../hooks/useRoutes";
import { useAccount } from "wagmi";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import useWalletDetails from "../../hooks/useWalletDetails";
import truncate from "../../utils/truncate";
import { Skeleton } from "@mui/material";
import getCosmosAddress from "../../utils/getCosmosAddress";
import { useAccount as useAccountGraz } from "graz";
function UpdateQuote({
  handleUpdate,
  startTimer,
  setDisableButton,
  reset,
  details,
}) {
  const configuration = useStore((state) => state.configuration);
  const persist = useStore((state) => state.persist);
  const { address } = useAccount();
  const { address: tronAddress } = useWallet();
  const { primaryWallet } = useDynamicContext();
  const { getWallet } = useWalletDetails({});
  const setPersist = useStore((state) => state.setPersist);
  const customWallet = useStore((state) => state.customWallet);
  const customFee = useStore((state) => state.customFee);
  const broadCastMode = useStore((state) => state.broadCastMode);
  const evmGas = useStore((state) => state.evmGas);
  const evmSpeed = useStore((state) => state.evmSpeed);
  const slippage = useStore((state) => state.slippage);
  const option = useStore((state) => state.option);
  const { data } = useAccountGraz();
  const routesDep = [
    "routesTxn",
    address,
    persist?.fromChain,
    persist?.toChain,
    persist?.fromCoin,
    persist?.toCoin,
    persist?.amount || persist?.route?.inputAmountDisplay || 0,
    primaryWallet?.address,
    tronAddress,
    customWallet,
    data?.bech32Address,
    customFee,
    evmGas,
    evmSpeed,
    slippage,
    broadCastMode,
    option.value,
  ];
  let solanaPriorityFee =
    (broadCastMode === "Priority Fee" || broadCastMode === "Both") &&
    customFee?.length
      ? customFee
      : option.value;
  let solanaJitoTip =
    (broadCastMode === "Jito Tip" || broadCastMode === "Both") &&
    customFee?.length
      ? customFee
      : option.value;
  let evmPriorityFee = evmGas?.length ? evmGas : evmSpeed.value;
  const feeQString =
    persist?.fromChain?.networkType === "sol"
      ? broadCastMode === "Priority Fee"
        ? `&solanaPriorityFee=${solanaPriorityFee}`
        : broadCastMode === "Jito Tip"
        ? `&solanaJitoTip=${solanaJitoTip}`
        : `&solanaPriorityFee=${solanaPriorityFee}&solanaJitoTip=${solanaJitoTip}`
      : persist?.fromChain?.networkType === "evm"
      ? `&evmPriorityFee=${evmPriorityFee}`
      : "";
  const { routes, routesData, setRoutesData, errorMessage, setErrorMessage } =
    useRoutes({
      enabled: true,
      dependency: routesDep,
      payload: [
        persist?.fromChain?.networkType === "cosmos"
          ? getCosmosAddress(
              data?.bech32Address,
              persist?.fromChain?.chainInfo?.bech32Config.bech32PrefixAccAddr
            )
          : persist?.route?.userWalletAddress ||
            getWallet(persist?.fromChain)?.address ||
            "",
        persist?.fromChain,
        persist?.toChain,
        persist?.fromCoin,
        persist?.toCoin,
        persist?.amount || persist?.route?.inputAmountDisplay || 0,
        Number(slippage) * 100,
        customWallet?.length > 30
          ? persist?.route?.recipient || getWallet({})?.address || ""
          : persist?.toChain?.networkType === "cosmos"
          ? getCosmosAddress(
              data?.bech32Address,
              persist?.toChain?.chainInfo?.bech32Config.bech32PrefixAccAddr
            )
          : persist?.route?.recipient ||
            getWallet(persist?.toChain)?.address ||
            "",
        feeQString,
      ],
    });
  let outputVal = truncate(Number(routesData?.outputAmountDisplay || 0), 6);
  async function callRoutes() {
    if (persist.isQuoteTimer === false) {
      let req = await routes.refetch();
      if (req.status === "success" && req.data.status === "success") {
        setPersist({
          ...useStore.getState().persist,
          isQuoteTimer: true,
        });
      }
    }
  }
  useEffect(() => {
    callRoutes();
  }, [persist.isQuoteTimer]);
  function handleQuote() {
    handleUpdate();
    setDisableButton(true);
    setPersist({
      stepData: {},
      quote: "enable",
      isTimerStarted: false,
      routeId: routesData?.routeId || "",
      route: routesData,
      toChain: persist?.toChain || {},
      toCoin: persist?.toCoin || {},
      fromChain: persist?.fromChain || {},
      fromCoin: persist?.fromCoin || {},
      amount: persist?.amount,
      openAt: new Date().getTime(),
      nextTx: null,
      startTime: new Date().getTime(),
      showAcceptQuote: false,
    });
  }
  return (
    <div className="bw-w-full bw-absolute bw-border-t bw-border-border-primary bw-pt-3 bw-bottom-0  bw-flex bw-flex-col bw-justify-center bw-items-center">
      <p className="bw-text-text-primary bw-text-lg bw-font-semibold">
        Quote Expired!
      </p>
      <p className="bw-text-text-secondary bw-text-sm bw-font-medium">
        Please accept the new quote, or go back to select manually
      </p>
      <div className="bw-w-full bw-mt-4 bw-px-2 bw-flex bw-items-center ba-gap-x-2 bw-justify-between">
        <div>
          {routes.isLoading || routes.isFetching ? (
            <Skeleton
              className="bw-bg-background-loaderbar bw-mb-1  bw-rounded-lg bw-h-[20px] bw-w-[150px] bw-min-h-[20px]"
              variant="rectangular"
              animation="wave"
              height={20}
              width={100}
            />
          ) : (
            <p className="bw-text-text-lg bw-font-bold bw-text-text-primary">
              {outputVal?.toString()?.length > 10
                ? outputVal
                : outputVal.toString()?.slice(0, 10)}{" "}
              {routesData?.to?.symbol || ""}
            </p>
          )}

          {routes.isLoading || routes.isFetching ? (
            <Skeleton
              className="bw-bg-background-loaderbar  bw-rounded-lg bw-h-[20px] bw-w-[70px] bw-min-h-[20px]"
              variant="rectangular"
              animation="wave"
              height={20}
              width={70}
            />
          ) : (
            <p className="bw-text-text-sm bw-font-normal bw-text-text-secondary">
              ${" "}
              {truncate(
                Number(
                  routesData?.minOutputAmountDisplay ||
                    routesData?.outputAmountDisplay
                ) * routesData?.to?.lastPrice || 0,
                4
              )}
            </p>
          )}
        </div>
        <button
          disabled={routes.isFetching || routes.isLoading}
          className={`bw-text-lg bw-flex bw-justify-center bw-items-center bw-gap-x-2 disabled:bw-opacity-60 bw-font-medium   bw-h-[50px] bw-w-1/2 bw-bg-background-container  bw-rounded-sm ${
            styles.gradientbutton + " bw-p-[3px]"
          }`}
          onClick={handleQuote}
          style={{
            ...configuration?.gradientStyle,
            boxShadow:
              (configuration &&
                configuration.customTheme &&
                configuration.customTheme?.shadow?.boxShadow) ||
              "1px 1px 7px rgba(0, 0, 0, 0.15)",
          }}
        >
          <p className="bw-w-full bw-h-full bw-bg-background-container bw-text-text-primary bw-font-bold  bw-flex bw-items-center bw-justify-center">
            Accept Quote
          </p>
        </button>
      </div>
    </div>
  );
}

export default UpdateQuote;
