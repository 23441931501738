import truncate from "../../utils/truncate";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import useStore from "../../zustand/store";
import styles from "./WidgetForm.module.css";
import Gas from "../Svg/gas";
import B from "../Svg/b";
import StepIcon from "../Svg/step";
import ClockIcon from "../Svg/clockIcon";
import Lottie from "lottie-react";
import SpinnerAnimation from "../../lottie/spinner.json";
import fixChar from "../../utils/fixChar";
import fixString from "../../utils/fixString";
import Spinner from "../../lottie/spinner";
import ethertousd from "../../utils/ethertousd";

export default function LoadRoute({
  routes,
  handleShowAllRoutes,
  routesData,
  price,
  amount,
  isRoutesEnabled,
}) {
  const data = routes.data;
  const [gasData, setGasData] = useState();
  const gradient = useStore((state) => state.gradient);
  const configuration = useStore((state) => state.configuration);
  const fromChain = useStore((state) => state.fromChain);
  const fromCoin = useStore((state) => state.fromCoin);
  const toChain = useStore((state) => state.toChain);
  const toCoin = useStore((state) => state.toCoin);
  useEffect(() => {
    if (routesData) {
      let tempGasObj = { amountInUSD: 0, amountInNative: 0 };
      let token = {};
      routesData?.fee?.forEach((item) => {
        if (item.source == "FROM_SOURCE_WALLET") {
          tempGasObj.amountInUSD += Number(item.amountInUSD);
          tempGasObj.amountInNative += Number(
            ethertousd(item.amountInToken, item.token.decimals)
          );
          token = { ...item };
        }
        // else if (item.type?.toLowerCase() == "network") {
        //   tempGasObj.amountInUSD += Number(item.amountInUSD);
        // }
      });
      setGasData({ tempGasObj, token });
    }
  }, [routesData]);
  let outputVal = truncate(Number(routesData?.outputAmountDisplay || 0), 10);
  return (
    <div className="bw-mt-2">
      {!(
        fromChain?.chain?.length &&
        fromCoin?.coin?.length &&
        toChain?.chain?.length &&
        toCoin?.coin?.length &&
        amount.length &&
        isRoutesEnabled
      ) ? (
        <></>
      ) : routes.isSuccess && !routes.data?.data?.quotes?.length ? (
        <div className="bw-bg-background-container bw-text-text-secondary bw-flex bw-justify-center bw-items-center bw-h-[80px] bw-relative  bw-flex-col  bw-border bw-border-border-primary">
          No Routes Available
        </div>
      ) : routes.isSuccess || routes.isLoading || routes.isFetching ? (
        <div>
          <div className="bw-flex bw-items-center bw-justify-between">
            {" "}
            <p className="bw-text-sm bw-font-medium bw-text-text-secondary">
              Get {toCoin.name}
            </p>
            {!routes.isFetching ? (
              <div
                onClick={handleShowAllRoutes}
                style={{ ...configuration.gradientStyle }}
                className={`bw-w-[130px] bw-cursor-pointer bw-relative bw-p-[1px]  bw-text-transparent bw-text-sm bw-font-normal  bw-h-[18px]  bw-rounded-xl   ${
                  gradient
                    ? gradient
                    : `${styles.overlayshow} ${
                        !configuration.gradientStyle?.background
                          ? "bw-bg-gradient-to-r bw-from-[#A45EFF] bw-to-[#2CFFE4]"
                          : ""
                      }`
                }`}
              >
                <div className=" bw-w-full bw-h-full bw-text-text-secondary bw-relative bw-z-10 bw-bg-background-container  bw-flex bw-justify-center bw-items-center bw-text-xs bw-font-normal  bw-rounded-xl">
                  Show All Routes
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className=" bw-mt-2">
            <div
              style={
                !routes.isFetching ? { ...configuration.gradientStyle } : {}
              }
              className={` ${
                routes.isFetching
                  ? "bw-bg-background-secondary"
                  : ` ${
                      gradient
                        ? gradient
                        : `${
                            !configuration.gradientStyle?.background
                              ? "bw-bg-gradient-to-r bw-from-[#A45EFF] bw-to-[#2CFFE4]"
                              : ""
                          } ${styles.overlay}`
                    } `
              }   bw-relative  bw-flex bw-justify-center bw-rounded-[8px] bw-w-full bw-p-[1px] bw-h-[86px] `}
            >
              <div className="bw-w-full bw-h-full bw-flex bw-px-2 bw-pb-3 bw-items-center bw-justify-between bw-bg-background-card rbw-elative bw-z-10 bw-rounded-[8px]">
                <div className="bw-flex bw-items-start bw-gap-x-2">
                  {toCoin?.image && !routes.isFetching ? (
                    <img
                      src={toCoin.image}
                      width={30}
                      height={30}
                      className="bw-mt-1  bw-bg-background-coin bw-rounded-[50%]"
                    />
                  ) : (
                    <></>
                  )}
                  <div>
                    {!routes.isFetching ? (
                      <p className="bw-text-3xl bw-mb-1 bw-font-medium bw-text-text-primary">
                        {fixChar(outputVal, 10)}{" "}
                      </p>
                    ) : (
                      <Skeleton
                        className="bw-bg-background-loaderbar bw-mt-3 bw-rounded-sm bw-h-[36px] bw-min-h-[36px]"
                        animation="wave"
                        variant="rectangular"
                        height={36}
                        width={250}
                      />
                    )}
                    {!routes.isFetching ? (
                      <div className="bw-flex bw-items-center bw-gap-x-1">
                        <Gas fill={"var(--tw-text-primary)"} />
                        {/* <p className="bw-text-sm bw-font-medium bw-text-text-primary">
                          {truncate(
                            Number(gasData?.token?.amountInEther) /
                              Math.pow(10, gasData?.token?.token?.decimals),
                            6
                          ) || 0}{" "}
                          {gasData?.token?.token?.symbol || ""}
                        </p> */}
                        <p className="bw-text-sm bw-font-medium bw-text-text-primary">
                          Total Fee:
                          {` $${
                            truncate(
                              gasData?.tempGasObj?.amountInUSD || 0,
                              6
                            ) || 0
                          } (${fixString(
                            gasData?.tempGasObj?.amountInNative.toFixed(20) ||
                              "",
                            10
                          )} ${gasData?.token?.token?.symbol || ""})
                            `}
                        </p>
                      </div>
                    ) : (
                      <Skeleton
                        className="bw-bg-background-loaderbar bw-mt-2 bw-rounded-sm bw-h-[20px] bw-w-[100px] bw-min-h-[20px]"
                        variant="rectangular"
                        animation="wave"
                        height={20}
                        width={100}
                      />
                    )}
                  </div>
                </div>
                <div className=" bw-text-sm bw-font-medium bw-mt-2  bw-text-text-loaderbar ">
                  {!routes.isFetching ? (
                    <p className="bw-text-text-secondary bw-font-medium bw-text-sm">
                      ${truncate(price, 4)}
                    </p>
                  ) : (
                    <Skeleton
                      className="bw-bg-background-loaderbar bw-w-[50px] bw-rounded-sm bw-h-[20px] bw-min-h-[20px]"
                      variant="rectangular"
                      animation="wave"
                      height={20}
                      width={50}
                    />
                  )}
                  {!routes.isFetching ? (
                    data?.data?.quotes?.[0]?._id === routesData._id ? (
                      <div
                        style={{ ...configuration.gradientStyle }}
                        className={`${
                          gradient
                            ? gradient
                            : `${
                                !configuration.gradientStyle?.background
                                  ? "bw-bg-gradient-to-r bw-from-[#A45EFF] bw-to-[#2CFFE4]"
                                  : ""
                              } `
                        } bw-mt-3 bw-text-right bw-text-transparent bw-text-[10px] bw-font-bold bw-bg-clip-text ${
                          styles.gradienttext
                        }`}
                      >
                        BEST
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <Skeleton
                      className="bw-bg-background-loaderbar bw-w-[50px] bw-mt-2 bw-rounded-sm bw-h-[20px] bw-min-h-[20px]  "
                      variant="rectangular"
                      animation="wave"
                      height={20}
                      width={50}
                    />
                  )}
                </div>
              </div>
              {routes.isFetching ? (
                <div className="bw-absolute bw-w-[40px] bw-h-[40px] bw-bottom-[-35%] bw-z-20 ">
                  {/* <Lottie animationData={SpinnerAnimation} loop={true} /> */}
                  <Spinner />
                </div>
              ) : (
                <></>
              )}
              <div className="bw-h-[18px] bw-w-max bw-z-10 bw-min-w-[200px] bw-bg-background-card  bw-flex bw-items-center bw-absolute bw-bottom-[-10%]">
                {!routes.isFetching && !isEmpty(routesData) ? (
                  <div className="bw-flex bw-w-full bw-px-6  bw-items-center bw-gap-x-1">
                    {" "}
                    <p className="bw-text-sm bw-w-full bw-text-center bw-flex bw-justify-center bw-items-center bw-font-normal bw-my-1 bw-text-text-secondary">
                      <span className="bw-text-sm bw-font-light">via</span>{" "}
                      <img
                        src={routesData?.providerDetails?.logoUrl}
                        className="bw-rounded-[50%] bw-mx-[4px] bw-min-w-[18px] bw-max-h-[18px] bw-min-h-[18px]"
                        width={18}
                        height={18}
                      />
                      {routesData?.providerDetails?.name || ""}:{" "}
                      {routesData?.protocolsUsed[0]}
                      {routesData?.protocolsUsed?.length <= 1 ? (
                        ""
                      ) : (
                        <span className="bw-ml-1 bw-text-xs bw-font-normal">
                          {`+${routesData?.protocolsUsed?.length - 1}`} More
                        </span>
                      )}{" "}
                    </p>{" "}
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={
                    !routes.isFetching ? { ...configuration.gradientStyle } : {}
                  }
                  className={` ${
                    routes.isFetching
                      ? "bw-bg-background-secondary"
                      : ` ${
                          gradient
                            ? gradient
                            : ` ${styles.overlay} ${
                                !configuration?.gradientStyle?.background
                                  ? "bw-bg-gradient-to-r bw-from-[#A45EFF] bw-to-[#2CFFE4]"
                                  : ""
                              }`
                        }`
                  }  bw-absolute  bw-right-0 bw-rounded-[50%] bw-w-[4px] bw-h-[4px]    `}
                ></div>
                <div
                  style={
                    !routes.isFetching ? { ...configuration.gradientStyle } : {}
                  }
                  className={` ${
                    routes.isFetching
                      ? "bw-bg-background-secondary"
                      : ` ${
                          gradient
                            ? gradient
                            : ` ${styles.overlay} ${
                                !configuration?.gradientStyle?.background
                                  ? "bw-bg-gradient-to-r bw-from-[#A45EFF] bw-to-[#2CFFE4]"
                                  : ""
                              }`
                        }`
                  }  bw-absolute   bw-rounded-[50%] bw-w-[4px] bw-h-[4px]    `}
                ></div>
              </div>
            </div>
            <div className="bw-bg-background-card bw-flex bw-items-center bw-w-[85%] bw-mx-auto bw-rounded-b-md bw-justify-center bw-h-[60px]">
              {!routes.isFetching ? (
                <div className="bw-flex bw-items-center bw-gap-x-4 bw-mt-2 bw-bg-background-card bw-justify-center ">
                  <div className="bw-flex bw-items-center bw-gap-x-1">
                    <B fill={"var(--tw-text-secondary)"} />
                    <p className="bw-text-sm bw-font-medium bw-text-text-secondary">
                      Fee
                    </p>
                    <p className="bw-text-sm bw-line-through bw-font-medium bw-text-text-secondary">
                      0.1%
                    </p>
                    <p className="bw-text-sm  bw-font-medium bw-text-text-secondary">
                      0
                    </p>
                  </div>
                  <div className="bw-flex bw-items-center bw-gap-x-1">
                    <ClockIcon fill={"var(--tw-text-secondary)"} />
                    <p className="bw-text-sm bw-font-medium bw-text-text-secondary">
                      {routesData?.estimatedTimeInSeconds >= 90
                        ? `${Math.round(
                            truncate(
                              (routesData?.estimatedTimeInSeconds || 60) / 60,
                              2
                            ) || 0
                          )}min`
                        : `${routesData?.estimatedTimeInSeconds || 60} secs`}
                    </p>
                  </div>
                  <div className="  bw-gap-x-1 bw-flex bw-items-center bw-text-xs bw-font-normal bw-text-text-primary">
                    <StepIcon fill={"var(--tw-text-secondary)"} />
                    <p className="bw-text-sm bw-font-medium bw-text-text-secondary">
                      {`${routesData?.steps?.length || ""} step`}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="bw-mt-6 bw-w-full bw-h-[20px]"></div>
              )}
            </div>
          </div>
        </div>
      ) : isRoutesEnabled ? (
        <div className="bw-bg-background-container bw-text-text-secondary  bw-justify-center bw-items-center bw-h-[80px] bw-relative bw-flex bw-flex-col  bw-border bw-border-border-primary">
          Failed to load routes
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

// {routes.isFetching ? (
//   <div className="mb-3 h-[170px]">
//     <div className="flex items-center gap-x-1 mb-1">
//       <p className="text-sm font-medium text-text-search">You Get</p>
//       {/* <img src={routeicon} width={13} height={9} alt="img" /> */}
//     </div>
//     <div className="border relative gap-x-1 mt-1 flex h-[144px] rounded-lg justify-center text-lg font-normal text-text-loading items-center border-border-primary">
//       <div className="absolute top-2 right-3">
//         <img src={refetchroute} />
//       </div>
//       <div className=" w-full flex items-center gap-x-4 px-3 justify-between">
//         <div className="w-[60%]  flex flex-col gap-y-1">
//           <Skeleton
//             className="bg-background-container rounded-sm h-[36px]  w-full"
//             animation="wave"
//             variant="rectangular"
//             height={36}
//           />
//           <Skeleton
//             height={20}
//             className="bg-background-container rounded-sm h-[20px]  w-full"
//             variant="rectangular"
//           />
//           <Skeleton
//             height={24}
//             className="bg-background-container rounded-sm h-[24px]  w-full"
//             variant="rectangular"
//           />
//         </div>

//         <div className="w-[40%] flex flex-col gap-y-1">
//           <Skeleton
//             height={20}
//             className="bg-background-container rounded-sm h-[20px]  w-full"
//             variant="rectangular"
//           />
//           <Skeleton
//             height={20}
//             className="bg-background-container rounded-sm h-[20px] w-full"
//             variant="rectangular"
//           />
//           <Skeleton
//             height={16}
//             className="bg-background-container rounded-sm h-[16px]  w-full"
//             variant="rectangular"
//           />
//         </div>
//       </div>
//     </div>
//   </div>
// ) :

// ) : routes.isSuccess && !routes.data?.data?.quotes?.length ? (
//   <div className="bg-background-container text-text-loading flrx justify-center items-center h-[80px] relative flex flex-col  border border-border-primary">
//     No Routes Available
//   </div>
//   ) : ( !isEmpty(routesData) && <div className="w-full h-[182px]"></div>
//   )}

// ? (
//   <div className="mb-3">
//     <div className="flex justify-between items-center mb-1">
//       <div className="flex items-center gap-x-1">
//         <p className="text-sm font-medium text-text-search">You Get</p>
//         {/* <img src={routeicon} width={13} height={9} alt="img" /> */}
//       </div>
//     </div>
//     <div className="bg-gradient-to-r from-[#A45EFF] to-[#2CFFE4] p-[1px] rounded-lg ">
//       <div className="bg-background-container relative flex flex-col pt-1 pb-6 items-center justify-center  rounded-lg ">
//         <div className="absolute top-2 right-3">
//           <img src={refetchroute} />
//         </div>
//         <div
//           onClick={handleShowAllRoutes}
//           className="w-[170px] cursor-pointer  absolute bottom-[-8%] text-transparent text-sm font-normal  h-[22px] bg-[#F5FFFD] rounded-xl border border-border-green1"
//         >
//           <div className=" w-full h-full bg-gradient-to-r from-[#2CFFE4] to-[#A45EFF]  flex justify-center items-center  bg-clip-text  rounded-xl">
//             Show All +
//             {routes.data?.data?.quotes?.length == 1
//               ? 1
//               : routes.data?.data?.quotes?.length - 1}{" "}
//             Routes
//           </div>
//         </div>
//         {Number(
//           data?.data?.quotes?.[0]?.minOutputAmount ||
//             data?.data?.quotes?.[0]?.outputAmountDisplay
//         ) ==
//         Number(
//           routesData.minOutputAmount || routesData.outputAmountDisplay
//         ) ? (
//           <p className="bg-  -to-r mt-1 mb-1 from-[#A45EFF] to-[#2CFFE4] text-xs tracking-[9px] font-semibold  bg-clip-text text-transparent">
//             RECOMMENDED
//           </p>
//         ) : (
//           <></>
//         )}
//         <div className="flex px-3 w-full items-center justify-between">
//           <div>
//             <p className="text-3xl font-medium text-text-route">
//               {Math.trunc(outputVal)?.toString()?.length > 8
//                 ? Math.trunc(outputVal)
//                 : outputVal.toString()?.slice(0, 8)}{" "}
//               {data?.data?.quotes?.[0]?.to?.symbol || ""}
//             </p>

//             <div className="flex items-center text-sm mt-1 text-text-primary gap-x-1">
//               <p>$ {truncate(price, 2)}</p>
//             </div>
//             <div className="flex  items-center gap-x-1">
//               <p className="text-base flex items-center font-normal my-1 text-text-primary">
//                 On{" "}
//                 {/* {routesData?.protocolsUsed.map((item, i, arr) => {
//                     return i == arr.length - 1 ? item : `${item + " & "}`;
//                   })}{" "} */}
//                 {routesData?.protocolsUsed[0]}
//                 {routesData?.protocolsUsed?.length <= 1 ? (
//                   ""
//                 ) : (
//                   <span className="ml-1">{`+${
//                     routesData?.protocolsUsed?.length - 1
//                   }`}</span>
//                 )}
//                 <img
//                   src={routesData?.providerDetails?.logoUrl}
//                   className="rounded-[50%] mx-[4px] min-w-[18px] max-h-[18px] min-h-[18px]"
//                   width={18}
//                   height={18}
//                 />
//                 via {routesData?.providerDetails?.name || ""}
//               </p>
//             </div>
//           </div>
//           <div className="flex justify-end flex-col items-end gap-y-2">
//             <div className="flex items-center gap-x-1">
//               <p className="text-sm font-medium text-text-primary">
//                 ${" "}
//                 {truncate(
//                   gasData?.amountInUSD ||
//                     ethertousd(
//                       gasData?.amountInEther,
//                       gasData?.token?.decimals
//                     ) ||
//                     0,
//                   4
//                 ) || 0}
//               </p>
//               <p className="text-sm font-medium text-text-primary">Gas</p>
//               <img src={gas} width={14} height={14} alt="img" />
//             </div>
//             <div className="flex items-center gap-x-1">
//               <p className="text-sm font-medium text-text-primary">{`${truncate(
//                 (routesData?.estimatedTimeInSeconds || 60) / 60,
//                 2
//               )}min`}</p>
//               <img src={clocknew} width={14} height={14} alt="img" />
//             </div>
//             <div className="  gap-x-1  text-xs font-normal text-text-primary">
//               <Step
//                 step={routesData?.steps?.length}
//                 provider={routesData?.providerDetails?.name}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="text-sm flex items-center gap-x-2 font-medium text-text-primary"></div>
//       </div>
//     </div>
//   </div>
