import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import controllers from "../Actions/Controllers";
import useRegisterError from "./useRegisterError";
import useStore from "../zustand/store";
import useWalletDetails from "../hooks/useWalletDetails";
export default function useRoutes({ enabled, dependency, payload }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [routesData, setRoutesData] = useState({});
  const userId = useStore((state) => state.userId);
  const { registerErrorMutateAsync, registerError } = useRegisterError();
  const { getWallet } = useWalletDetails({});
  const fromChain = useStore((state) => state.fromChain);
  const routes = useQuery({
    queryKey: dependency,
    queryFn: async () => {
      let res = await controllers.fetchRoutes(...payload);
      return await res.json();
    },

    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    enabled: enabled,
  });
  useEffect(() => {
    if (routes.data) {
      let data = routes.data;
      if (data.status == "error") {
        setErrorMessage(data);
        // registerError({ module: "/quotes", error: data.error });
      } else {
        setErrorMessage({ error: "" });
      }
      setRoutesData(data?.data?.quotes?.[0] || {});
    }
  }, [routes.data]);
  useEffect(() => {
    if (routes.error) {
      let err = routes.error;
      let error = err.details || err.message || "";
      setErrorMessage({ error });
      // registerError({
      //   module: "/quotes",
      //   error,
      // });
    }
  }, [routes.error]);
  useEffect(() => {
    if (routes.isLoading || routes.isFetching) {
      setErrorMessage({ error: "" });
    }
  }, [routes.isLoading, routes.isFetching]);
  return { routes, errorMessage, routesData, setRoutesData, setErrorMessage };
}
