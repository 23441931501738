import React, { useEffect, useState } from "react";
import useStore from "../../zustand/store";
import {
  useAccount,
  useDisconnect,
  useSwitchAccount,
  useConnectors,
  useConnections,
  useConnect,
} from "wagmi";
import getIsInstalled from "../SelectWallet/getisInstalled";
import InstalledIcon from "../InstalledIcon";
import images from "../../images";
import { keyBy } from "lodash";
import config from "../../service/wagmiConfig";
import DisconnectWallet from "../Svg/disconnectWallet";
function EvmWallet({
  selectedWalletTab,
  styles,
  connectWalletField,
  handleShowWallet,
  setSelectedConnector,
  setSelectedWallet,
  handleConnect,
  setShowLoading,
}) {
  const configuration = useStore((state) => state.configuration);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const { isConnected, connector } = useAccount();
  const { disconnect } = useDisconnect();
  const [connectorObj, setConnectorObj] = useState();
  const { connectors: switchC, switchAccountAsync } = useSwitchAccount();
  const {
    injectedicon,
    metamaskicon,
    coinbaseicon,
    walletconnecticon,
    ethereumicon,
    disconnecticon,
  } = images;
  const walletIcons = {
    injected: injectedicon,
    metaMaskSDK: metamaskicon,
    coinbaseWalletSDK: coinbaseicon,
    walletConnect: walletconnecticon,
  };
  const connectors = useConnectors({ config });
  const connections = useConnections();
  useEffect(() => {
    setConnectorObj(keyBy(connections, (item) => item.connector.uid));
  }, [connections]);
  return selectedWalletTab == "All" || selectedWalletTab == "EVM" ? (
    connectors.map((item, i) => {
      return (
        <div
          style={
            connector?.id == item?.id
              ? {
                  ...configuration?.gradientStyle,
                }
              : {}
          }
          className={`bw-w-[47%] bw-mb-4  bw-relative bw-rounded-md bw-p-[1px] ${
            !configuration.gradientStyle && connector?.id == item?.id
              ? styles.gradientbutton
              : "bw-bg-border-primary"
          } `}
        >
          <div
            key={i}
            onClick={() => {
              if (connector?.id == item?.id) {
                if (connectWalletField == "to") {
                  setToDisconnect(false);
                  handleShowWallet();
                } else {
                  disconnect({ connector });
                }
              } else {
                setSelectedWallet(item.name || item.type);
                setSelectedConnector(item);
                handleConnect(item, connectorObj?.[item.uid] ? true : false);
                setShowLoading(false);
                setToDisconnect(false);
              }
            }}
            style={{ cursor: "pointer" }}
            className={`bw-w-full bw-bg-background-container hover:bw-shadow-sm bw-font-medium hover:bw-font-bold  hover:bw-text-text-primary  bw-flex bw-gap-x-3  bw-px-3 bw-justify-start bw-items-center bw-h-[56px] ${
              connector?.uid == item?.uid
                ? " bw-text-text-primary"
                : "bw-text-text-secondary"
            } bw-relative  bw-rounded-md
        ${""}
        `}
          >
            {connector?.uid == item.uid || connectorObj?.[item.uid] ? (
              <div
                className={`bw-text-[10px]  bw-flex bw-items-center bw-gap-x-1 bw-mt-[-2px] bw-font-medium bw-absolute bw-top-1 bw-right-2`}
              >
                <div
                  style={{ ...configuration?.gradientStyle }}
                  className={`bw-w-[10px] bw-rounded-[50%] ${
                    !configuration?.gradientStyle ? styles.gradientbutton : ""
                  } bw-bg-text-greentext bw-h-[10px]`}
                ></div>{" "}
                <p
                  style={{ ...configuration?.gradientStyle }}
                  className={` bw-bg-clip-text bw-text-transparent ${
                    !configuration?.gradientStyle ? styles.gradientbutton : ""
                  } `}
                >
                  Connected
                </p>
              </div>
            ) : (
              <div className="bw-absolute bw-top-[2px] bw-right-1">
                <p className="bw-text-text-secondary bw-text-xs bw-font-normal">
                  Installed
                </p>
              </div>
            )}

            <div className="bw-w-max bw-relative">
              {" "}
              <img
                className="bw-rounded-md"
                src={walletIcons[item.id] || item.icon}
                width={32}
                height={32}
                alt="img"
              />
              <img
                src={ethereumicon}
                className="bw-absolute bw-right-[-15%] bw-bottom-[-30%]"
                width=""
                height=""
                alt="img"
              />
            </div>
            <p className="bw-text-xs">EVM</p>
            <p className="bw-text-xs bw-w-max ">{item.name}</p>
          </div>
          {connectorObj?.[item.uid] ? (
            <div
              onClick={(e) => {
                disconnect({
                  connector: connectorObj?.[item.uid]?.connector,
                  config,
                });
                e.stopPropagation();
              }}
              className=" bw-cursor-pointer bw-absolute bw-right-[5px] bw-bottom-1 bw-text-[10px] bw-text-text-primary  bw-z-40 bw-rounded-[50%]"
            >
              <div className="bw-w-[20px] bw-h-[20px]">
                <DisconnectWallet fill={"var(--tw-text-primary)"} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    })
  ) : (
    <></>
  );
}

export default EvmWallet;
