import React, { useState, useEffect } from "react";
import OptionsComp from "./OptionsComp";
import CustomComp from "./CustomComp";
import SaveButton from "./SaveButton";
import useStore from "../../../zustand/store";
import Header from "./Header";
import SlippageComp from "./SlippageComp";
import TransactionFeeHeading from "./TransactionFeeHeading";
import { set } from "lodash";
function EVMFeeComp({ handleClose }) {
  const [options, setOptions] = useState({ name: "Low", value: "low" });
  const [inputVal, setInputVal] = useState("");
  const [slippage, setSlippage] = useState("");
  const setEvmGas = useStore((state) => state.setEvmGas);
  const setEvmSpeed = useStore((state) => state.setEvmSpeed);
  const evmSpeed = useStore((state) => state.evmSpeed);
  const evmGas = useStore((state) => state.evmGas);
  const slippageBlobal = useStore((state) => state.slippage);
  const setSlippageGlobal = useStore((state) => state.setSlippage);
  const isSaveButtonDisabled =
    inputVal == evmGas &&
    evmSpeed.name == options.name &&
    slippageBlobal == slippage;
  function handleOptions(item, isInputReset) {
    if (isInputReset) {
      // setInputVal("");
      // setEvmGas("");
    }
    setOptions(item);
  }
  function handleCustomVal(e) {
    setInputVal(e.target.value);
  }
  useEffect(() => {
    setOptions(evmSpeed);
  }, []);
  useEffect(() => {
    evmGas != null && evmGas.length ? setInputVal(evmGas) : setInputVal("");
  }, [evmGas]);
  function handleSave() {
    if (inputVal.length) {
      setEvmGas(inputVal);
      setEvmSpeed(options);
    } else {
      setEvmGas("");
      setEvmSpeed(options);
    }
    setSlippageGlobal(slippage);
    handleClose();
  }
  useEffect(() => {
    setSlippage(slippageBlobal);
  }, [setSlippageGlobal]);
  function handleSlippage(e) {
    setSlippage(e.target.value);
  }

  return (
    <div className="bw-w-full md:bw-w-[443px] bw-h-[396px] bw-rounded-md bw-border bw-p-3 bw-border-border-primary bw-text-white bw-bg-background-container">
      <Header handleClose={handleClose} />
      <div className="bw-mt-5 bw-mb-5">
        <SlippageComp slippage={slippage} handleSlippage={handleSlippage} />
      </div>
      <div className="bw-mb-5">
        <TransactionFeeHeading />
      </div>
      <OptionsComp options={options} handleOptions={handleOptions} />
      <CustomComp
        title="Gas Price"
        inputSideText="GWEI"
        inputVal={inputVal}
        handleCustomVal={handleCustomVal}
      />
      <SaveButton
        handleSave={handleSave}
        isSaveButtonDisabled={isSaveButtonDisabled}
      />
    </div>
  );
}

export default EVMFeeComp;
