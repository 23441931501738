import React from "react";
import { useMemo, useState, useRef, useEffect } from "react";
import useStore from "../../zustand/store";
import Down from "../Svg/down";
import fixString from "../../utils/fixString.js";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter.js";
import getGas from "../../utils/getGas.js";
function ShowDetails() {
  const persist = useStore((state) => state.persist);
  return (
    <div className="bw-w-full bw-px-4 bw-py-2  bw-border-t bw-border-border-primary">
      <div>
        {persist?.route?.fee
          ?.filter((item, i) => item.source == "FROM_SOURCE_WALLET")
          .map((item, i) => {
            return item.amountInUSD ? (
              <div
                key={i}
                className="bw-flex bw-mb-1   bw-items-center bw-justify-between"
              >
                <p className="bw-text-text-secondary bw-text-sm bw-font-semibold">
                  {`${capitalizeFirstLetter(item.type)} Fee`}
                </p>
                <span className="bw-text-text-secondary bw-text-sm bw-font-bold">
                  {`${fixString(
                    getGas(item.amountInToken, item.token.decimals).toFixed(
                      20
                    ) || "",
                    6
                  )}  ${item.token.symbol} ($${fixString(
                    Number(item.amountInUSD),
                    6
                  )})`}
                </span>
              </div>
            ) : (
              <></>
            );
          })}
      </div>
      <div className="bw-w-full bw-h-[1px] bw-border-b bw-my-2 bw-border-border-primary"></div>
      <div className="bw-w-full">
        <div className="bw-flex bw-items-center bw-mb-2 bw-justify-between">
          <p className="bw-text-text-primary  bw-text-sm bw-font-medium">
            Price Impact
          </p>
          <span className="bw-text-text-primary bw-text-sm bw-font-medium"></span>
        </div>
        <div className="bw-flex bw-items-center bw-mb-2  bw-justify-between">
          <p className="bw-text-text-primary bw-text-sm bw-font-medium">
            Max Slippage
          </p>
          <span className="bw-text-text-primary bw-text-sm bw-font-medium">
            {(persist?.route?.slippage || 0) / 100}%
          </span>
        </div>
        <div className="bw-flex bw-items-center bw-justify-between">
          <p className="bw-text-text-primary bw-text-sm bw-font-medium">
            Minimum Received
          </p>
          <span className="bw-text-text-primary bw-text-sm bw-font-medium">
            {persist?.route?.minOutputAmountDisplay ||
            persist?.route?.minOutputAmount
              ? `${fixString(
                  persist.route.minOutputAmountDisplay ||
                    persist.route.minOutputAmount,
                  10
                )} ${persist?.route?.to?.symbol}`
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
}

function DownButton({ isShowFull }) {
  return (
    <button className="bw-w-[20px] bw-h-[20px]  bw-flex bw-items-center bw-justify-center bw-border bw-border-border-primary bw-rounded-full bw-text-text-secondary bw-text-lg bw-font-normal">
      <div className={`bw-mt-[1px] ${isShowFull ? "bw-rotate-180" : ""}`}>
        <Down fill={"var(--tw-text-primary)"} />
      </div>
    </button>
  );
}
function DefaultStateSection({ gasData }) {
  const persist = useStore((state) => state.persist);
  return (
    <div className="bw-flex bw-w-full bw-h-full bw-items-center">
      <span className="bw-text-xs md:bw-text-sm bw-font-normal bw-text-text-secondary">
        Max Slippage:
      </span>
      <span className="bw-text-sm bw-ml-[3px] bw-font-bold bw-text-text-secondary">
        {(persist?.route?.slippage || 0) / 100}%
      </span>
      <div className="bw-w-[2px] bw-h-[24px] bw-mx-3 bw-bg-text-secondary"></div>
      <span className="bw-text-sm bw-font-normal bw-text-text-secondary">
        Fees:
      </span>
      <span className="bw-text-xs md:bw-text-sm bw-ml-[3px]   bw-font-bold bw-text-text-secondary">
        {`${fixString(gasData?.payableInToken, 6)} ${gasData?.symbol} ${
          gasData?.payableInUSD
            ? `($${fixString(gasData?.payableInUSD, 6)})`
            : ""
        } `}
      </span>
    </div>
  );
}
function FullStateSection({ gasData }) {
  return (
    <div className="bw-w-full bw-flex bw-items-center bw-justify-between bw-border-border-primary">
      <span className="bw-text-sm bw-font-bold bw-text-text-primary">Fees</span>
      <span className="bw-text-sm bw-ml-[3px]  bw-font-bold bw-text-text-primary">
        {`${fixString(gasData?.payableInToken, 6)} ${gasData?.symbol} ${
          gasData?.payableInUSD
            ? `($${fixString(gasData?.payableInUSD, 6)})`
            : ""
        } `}
      </span>
    </div>
  );
}
function Summary({ gasData, isShowFull }) {
  return (
    <div className="bw-flex bw-items-center bw-justify-between bw-gap-x-2 bw-h-[35px]  bw-px-3 md:bw-px-4">
      {isShowFull ? (
        <FullStateSection gasData={gasData} />
      ) : (
        <DefaultStateSection gasData={gasData} />
      )}
      <DownButton isShowFull={isShowFull} />
    </div>
  );
}
function EstGas() {
  const persist = useStore((state) => state.persist);
  const [showFull, setShowFull] = useState(false);
  const configuration = useStore((state) => state.configuration);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  console.log(contentHeight, "content");
  useEffect(() => {
    if (contentRef.current) {
      const timeoutId = setTimeout(() => {
        const height = contentRef.current.scrollHeight;
        setContentHeight(height);
      }, 50);

      return () => clearTimeout(timeoutId);
    }
  }, [persist?.route, showFull]);

  function handleShowFull() {
    setShowFull(!showFull);
  }

  const gasData = useMemo(() => {
    let tempGasObj = {
      payableInUSD: 0,
      payableInToken: 0,
      symbol: "",
    };
    persist?.route?.fee?.forEach((item) => {
      if (item.source == "FROM_SOURCE_WALLET") {
        tempGasObj.payableInUSD += Number(item.amountInUSD);
        tempGasObj.payableInToken += getGas(
          Number(item.amountInToken),
          item.token.decimals
        );
        tempGasObj.symbol = item.token.symbol;
      }
    });
    return tempGasObj;
  }, [persist?.route]);
  return (
    <div
      onClick={() => {
        handleShowFull();
      }}
      style={{
        boxShadow:
          (configuration &&
            configuration.customTheme &&
            configuration.customTheme?.shadow?.boxShadow) ||
          "0px 0px 5.8px 0px #00000026",
      }}
      className={`bw-w-full bw-sticky bw-top-0 bw-z-20 bw-select-none bw-cursor-pointer bw-bg-background-card bw-rounded-lg bw-min-h-max bw-border bw-border-border-primary bw-overflow-hidden`}
    >
      <Summary gasData={gasData} isShowFull={showFull} />
      <div
        ref={contentRef}
        style={{
          height: showFull ? `${contentHeight}px` : "0",
          transition: "height 0.3s ease-in-out",
          overflow: "hidden",
          visibility: contentHeight === 0 ? "hidden" : "visible",
        }}
      >
        <div className="bw-h-auto">
          <ShowDetails showFull={showFull} />
        </div>
      </div>
    </div>
  );
}

export default EstGas;
