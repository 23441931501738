import TokenBox from "../Exchange/TokenBox";
import React, { useEffect, useState } from "react";
import Header from "../Exchange/Header";
import TxnTimerDetails from "./TxnTimerDetails";
import { useQuery } from "@tanstack/react-query";
import controllers from "../../Actions/Controllers";
import useStore from "../../zustand/store";
import { keyBy } from "lodash";
import ProviderCompDetails from "./ProviderCompDetails";
import EstGasDetails from "./EstGasDetails";
import TransactionDetailStep from "./TransactionDetailStep";
import StatusComp from "./StatusComp";
import TxnExecutionComp from "./TxnExecutionComp";
import TransactionComp from "../Exchange/TransactionComp";
import { TxnSuccessContext } from "../Context/txnSuccessContext";
import { TxnErrorContext } from "../Context/txnErrorContext";
import TxnTimer from "../TxnTimer";
import images from "../../images";
import EstGas from "../Exchange/EstGas";
import useRegisterError from "../../hooks/useRegisterError";
const TransactionDetailsComp = () => {
  const setRoute = useStore((state) => state.setRoute);
  const txnDetails = useStore((state) => state.txnDetails);
  const [isTxnSuccessful, setIsTxnSuccessful] = useState(false);
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [isErrorMessage, setIsErrormessage] = useState(false);
  const [chainData, setChainData] = useState({});
  const setPersist = useStore((state) => state.setPersist);
  const persist = useStore((state) => state.persist);
  const { registerError } = useRegisterError();
  const { txnupdate } = images;
  const details = useQuery({
    queryKey: ["txnDetails", txnDetails?.routeId],
    queryFn: async () => {
      let req = await controllers.fetchTxnDetails(txnDetails?.routeId);
      return await req.json();
    },

    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (details.data) {
      let data = details.data;
      if (data.status == "error") {
        // registerError({ module: "/txn-details", error: data.error });
      }
      let stepStatusArray = [];
      if (data?.data?.currentStepStatus === "success") {
        data?.data?.steps?.forEach((item) => {
          stepStatusArray.push({ type: "preText" });
        });
      } else if (data?.data?.currentStepStatus === "failed") {
        console.log("isErrorMessage,failed");
        setIsErrormessage(true);
        data?.data?.steps?.forEach((item) => {
          if (item?.status === "success") {
            stepStatusArray.push({ type: "preText" });
          } else if (item?.status === "failed") {
            stepStatusArray.push({ type: "failed" });
          }
        });
      }
      setPersist({
        ...useStore.getState()?.persist,
        stepData: data?.data?.steps[data?.data?.currentStepIndex],
        routeId: data?.data?.routeId,
        allSteps: {
          steps: data?.data?.steps,
          currentStep:
            data?.data?.currentStepStatus === "success"
              ? data?.data?.currentStepIndex + 1
              : data?.data?.currentStepIndex,
          type: "preText",
        },
        stepStatus: stepStatusArray,
        statusData: {
          status: data?.data?.currentStepStatus,
        },
        historyData: data?.data,
        fromCoin: data?.data?.from,
        toCoin: data?.data?.to,
        route: data?.data,
        isHistory: true,
        hash:
          data?.data?.currentStepStatus == "in-progress"
            ? data?.data?.steps[data?.data?.currentStepIndex]?.srcTxnHash
            : null,
      });
    }
  }, [details.data]);
  useEffect(() => {
    if (details.error) {
      let err = details.error;
      let error = err.details || err.message || "";
      // registerError({ module: "/txn-details", error });
    }
  }, [details.error]);
  const chains = useQuery({
    queryKey: ["chains"],
    queryFn: async () => {
      let req = await controllers.fetchChain();
      return await req.json();
    },
    refetchOnWindowFocus: false,
    enabled: details.isSuccess,
  });
  useEffect(() => {
    if (chains.data) {
      let data = chains.data;
      let chainObj = { ...keyBy(data.data, "chainId") };
      setChainData(chainObj);

      setPersist({
        ...useStore.getState()?.persist,
        fromChain: chainObj[details?.data?.data?.from?.chainId],
        toChain: chainObj[details?.data?.data?.to?.chainId],
      });
    }
  }, [chains.data]);
  function handleBack() {
    setRoute("history");
    setPersist({});
  }
  let txnDetailsData = details?.data?.data || {};
  function handleTxnSuccess(val) {
    setIsTxnSuccessful(val);
  }
  function handleErrorMessage(val) {
    setIsErrormessage(val);
  }
  function startTimer(val) {
    setIsTimerStarted(val);
  }

  return (
    <TxnSuccessContext.Provider value={{ isTxnSuccessful, handleTxnSuccess }}>
      <TxnErrorContext.Provider value={{ isErrorMessage, handleErrorMessage }}>
        <div className="bw-w-full  bw-relative bw-min-h-[610px] ">
          {details.isSuccess ? (
            <>
              <Header handleBack={handleBack} />
              <div className="bw-mt-[-20px]">
                <ProviderCompDetails details={txnDetailsData} />
              </div>
              <div className="bw-flex  bw-justify-between bw-items-center"></div>
              <div className="bw-flex bw-mt-4  bw-w-full bw-justify-between bw-items-center">
                <div className="bw-w-[40%] md:bw-w-auto">
                  <p className="bw-text-xs bw-mb-1 bw-text-text-secondary bw-font-medium">
                    Send
                  </p>
                  <TokenBox
                    amount={txnDetailsData.inputAmountDisplay}
                    coinData={txnDetailsData.from}
                    chainData={chainData[txnDetailsData?.from?.chainId] || {}}
                    field={"from"}
                  />
                </div>
                {/* <TxnTimerDetails status={txnDetailsData?.currentStepStatus || ""} /> */}
                {isErrorMessage ? (
                  <div
                    className={`bw-border bw-mt-5 bw-w-[65px] bw-h-[65px] bw-flex bw-items-center bw-justify-center bw-rounded-[50%] bw-bg-background-card  bw-relative bw-z-10  hover:bw-opacity-100  bw-border-border-primary `}
                  >
                    <img src={txnupdate} width={33} height={30} alt="img" />
                  </div>
                ) : (
                  <TxnTimer
                    isTimerStarted={persist?.isTimerStarted || isTimerStarted}
                    startTimer={startTimer}
                    error={isErrorMessage}
                  />
                )}
                <div className="bw-w-full bw-z-0 md:bw-w-full bw-mt-5  bw-absolute bw-h-[1px] bw-bg-border-primary "></div>
                <div className="bw-w-[40%] md:bw-w-auto">
                  <p className="bw-text-xs bw-mb-1 bw-text-text-secondary bw-font-medium">
                    Receive
                  </p>
                  <TokenBox
                    amount={txnDetailsData.outputAmountDisplay}
                    coinData={txnDetailsData.to}
                    chainData={chainData[txnDetailsData?.to?.chainId] || {}}
                    field={"to"}
                  />
                </div>
              </div>
              <div className="bw-h-[320px] bw-pr-1 bw-flex bw-flex-col bw-mt-8 bw-overflow-y-auto">
                {details.isLoading ? <></> : <EstGas />}
                {/* <TxnExecutionComp details={txnDetailsData} chainData={chainData} /> */}

                <TransactionComp
                  amount={details?.inputAmountDisplay}
                  startTimer={startTimer}
                  handleBack={handleBack}
                  isHistory={true}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </TxnErrorContext.Provider>
    </TxnSuccessContext.Provider>
  );
};

export default TransactionDetailsComp;
