import React from "react";
import images from "../../images";
import useStore from "../../zustand/store";
import fixString from "../../utils/fixString";
import styles from "./Exchange.module.css";
import TxnHash from "../Tooltip/TxnHash";
import Copy from "../Svg/copy";
function TransactionUpdateComp({ handleBack }) {
  const { txnupdate } = images;
  const persist = useStore((state) => state.persist);
  const configuration = useStore((state) => state.configuration);
  const lastStepType = persist?.stepData.stepType;
  const txnHashObj = persist?.txnHashObj || [];
  const lastTxnHash = txnHashObj[persist?.stepData?.stepId] || {};
  const txnHashCurrent =
    lastStepType === "bridge"
      ? lastTxnHash?.destTxnHash || lastTxnHash?.srcTxnHash
      : lastTxnHash?.srcTxnHash || "";
  const txnHashHistory = persist?.historyData?.finalTxnHash;
  const txnHash = txnHashCurrent || txnHashHistory || "";
  const txnUrlCurrent =
    lastStepType === "bridge"
      ? lastTxnHash?.destTxnUrl || lastTxnHash?.srcTxnUrl
      : lastTxnHash?.srcTxnUrl || "";
  const txnUrlHistory = persist?.historyData?.finalTxnUrl;
  const txnUrl = txnUrlCurrent || txnUrlHistory || "";
  const toCoin = persist.toCoin || {};
  const route = persist.route || {};
  const toChain = persist.toChain || {};
  return (
    <div className="bw-absolute bw-bg-background-container bw-bottom-0">
      <div className="bw-w-full bw-flex bw-items-center bw-p-3 bw-h-[141px] bw-rounded-xl bw-border bw-border-border-primary">
        <div className="bw-w-[60%]">
          <p className="bw-text-text-secondary bw-mb-2 bw-text-lg bw-font-medium">
            Transaction Update
          </p>
          <p className="bw-text-text-primary   bw-text-2xl bw-font-bold">
            {fixString(persist?.statusData?.outputAmountDisplay || "", 10)}{" "}
            {persist?.statusData?.outputToken?.symbol || ""} <br />
          </p>
          <p className="bw-text-text-secondary bw-mb-2 bw-text-sm bw-font-normal">
            Received on {persist.toChain.name} chain{" "}
          </p>
          <div className="bw-text-text-secondary bw-flex bw-items-center bw-gap-x-1  bw-mb-2 bw-text-sm bw-font-medium">
            {`Txn Id:${
              txnHash
                ? `${txnHash.substring(0, 5)}...${txnHash.substring(
                    txnHash.length - 5,
                    txnHash.length
                  )}`
                : ""
            }`}
            <div className="bw-cursor-pointer ">
              <TxnHash txnHash={txnHash}>
                <div>
                  <Copy fill={"var(--tw-text-secondary)"} />
                </div>
              </TxnHash>
            </div>
          </div>
        </div>
        <div className="bw-w-[40%] bw-flex bw-items-center bw-justify-center">
          <img src={txnupdate} width={108} height={100} alt="img" />
        </div>
      </div>
      <p className="bw-text-sm bw-text-center bw-text-text-secondary bw-font-normal bw-mt-3">
        Due to liquidity error on providers end your{" "}
        <b className="bw-text-text-primary">
          receiving token has been changed to{" "}
          {persist?.statusData?.outputToken?.symbol || ""} on{" "}
          {persist?.statusData?.outputToken?.blockchain}
        </b>
      </p>
      <button
        onClick={handleBack}
        style={{ ...configuration?.gradientStyle }}
        className={`bw-w-full ${styles.gradientbutton} bw-rounded-sm bw-h-[50px] bw-p-[3px] bw-mt-3 bw-border-0 bw-relative bw-z-0  disabled:bw-opacity-50  bw-text-2xl bw-font-bold bw-text-text-primary`}
      >
        <div
          onClick={handleBack}
          className="bw-rounded-sm bw-bg-background-container bw-flex bw-items-center bw-justify-center b-w-full bw-h-full"
        >
          Start Again
        </div>
      </button>
    </div>
  );
}

export default TransactionUpdateComp;
