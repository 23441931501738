import React from "react";
import CreateTxError from "../Svg/createTxError";
function CreateTxComp() {
  return (
    <div
      style={{ wordBreak: "break-all" }}
      className="bw-text-text-error bw-mb-12  bw-w-full bw-text-sm bw-flex bw-flex-col bw-justify-center bw-items-center bw-text-center bw-text-text-redtext"
    >
      <CreateTxError
        fill={"var(--tw-background-networkcard)"}
        stroke={"var(--tw-border-primary)"}
      />
      <p className="bw-text-2xl bw-mt-3 bw-text-text-primary">
        Unable to complete the request!
      </p>
      <p className="bw-text-sm bw-mt-2 bw-font-normal bw-text-text-secondary">
        Please start your transaction again
      </p>
      {/* {errorText.length > 400
    ? errorText.slice(0, 400)
    : errorText || ""} */}
    </div>
  );
}

export default CreateTxComp;
