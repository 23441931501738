import React from "react";
import useStore from "../../zustand/store";

function HeadingText() {
  const configuration = useStore((state) => state.configuration);
  const customStyles = configuration?.headingStyles;
  const heading = configuration?.headingText || "";
  return heading.length ? (
    <span
      style={{
        transform: "skewX(20deg)",
        letterSpacing: "0.05em",
        ...customStyles,
      }}
    >
      {heading}
    </span>
  ) : (
    <span style={{ transform: "skewX(20deg)" }} className="bw-tracking-wider">
      LA
      <span style={{ display: "inline-block", transform: "rotate(15deg)" }}>
        Z
      </span>
      Y.exchange
    </span>
  );
}

export default HeadingText;
