import React from "react";
import WalletIcon from "../Svg/walletIcon";
import { CircularProgress } from "@mui/material";
import useStore from "../../zustand/store";
function AddressComp({ field }) {
  const persist = useStore((state) => state.persist);
  const address =
    field == "from"
      ? persist?.route?.userWalletAddress || ""
      : persist?.route?.recipient || "";
  return (
    <button
      style={{ fontSize: "12px" }}
      className="bw-bg-background-card  bw-flex bw-items-center bw-justify-center bw-gap-x-2 bw-mb-[4px] bw-w-[100px] bw-h-[25px] bw-rounded-xl bw-border bw-border-border-primary"
    >
      {address ? (
        <>
          <p className="bw-text-text-primary bw-text-xs bw-font-normal">
            {(address?.substring(0, 4) || "") +
              "..." +
              (address?.substring(address.length - 4, address.length) || "")}
          </p>
          <div>
            <WalletIcon fill={"var(--tw-text-primary)"} />
          </div>
        </>
      ) : (
        <div className="bw-w-[10px] bw-flex bw-text-text-secondary bw-justify-center bw-items-center bw-h-[10px]">
          <CircularProgress size={10} thickness={5} color="inherit" />
        </div>
      )}
    </button>
  );
}

export default AddressComp;
