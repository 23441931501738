import { useAccount, useDisconnect, useBalance, useConnections } from "wagmi";
import {
  useDynamicContext,
  useIsLoggedIn,
  useUserWallets,
} from "@dynamic-labs/sdk-react-core";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  mainnetChains,
  useAccount as useCosmosAccount,
  useConnect,
  useDisconnect as useCosmosDisconnect,
} from "graz";
import useStore from "../zustand/store";
export default function useWalletDetails({ chainData, show }) {
  //evm
  const { address, isConnected, chain } = useAccount();
  const { data } = useBalance({ address });
  const { disconnect, disconnectAsync } = useDisconnect();
  let evmWallet = { address, data, disconnect: disconnectAsync, chain };
  const cosmosChainIds = useStore((state) => state.cosmosChainIds);
  const evmConnections = useConnections();
  const wallets = useUserWallets();

  const {
    data: cosmosData,
    isConnected: isCosmosConnected,
    WalletType,
    reconnect,
  } = useCosmosAccount({
    chainId: cosmosChainIds,
    multiChain: true,
  });
  const { disconnect: cosmosDisconnect } = useCosmosDisconnect();
  const setCustomWallet = useStore((state) => state.setCustomWallet);
  const customWallet = useStore((state) => state.customWallet);
  const toDisconnect = useStore((state) => state.toDisconnect);
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  //solana
  const { primaryWallet, handleLogOut } = useDynamicContext();
  const isFullyConnected = useIsLoggedIn();
  let solWallet = {
    address: primaryWallet?.address || "",
    disconnect: handleLogOut,
    chain: { network: "Solana" },
  };
  //tron
  const {
    address: tronAdrress,
    disconnect: tronDisconnect,
    connected,
  } = useWallet();
  let tronWallet = {
    address: tronAdrress || "",
    disconnect: tronDisconnect,
    chain: { network: "tron" },
  };
  let cosmosWallet = {
    address: cosmosData?.[chainData?.chainId]?.bech32Address || "",
    disconnect: cosmosDisconnect,
    chain: { network: "cosmos" },
  };
  let customWalletDetail = {
    address: customWallet,
    disconnect: setCustomWallet,
    chain: { network: "custom" },
  };
  const walletAddress = {
    evm: { address: address, connected: isConnected, name: "Ethereum" },
    sol: {
      address: primaryWallet?.address || "",
      connected: isFullyConnected,
      name: "Solana",
    },
    tron: { address: tronAdrress, connected: connected, name: "Tron" },
    cosmos: {
      address: cosmosData?.[chainData?.chainId]?.bech32Address || "",
      connected: isCosmosConnected,
      name: "Cosmos",
    },
    customWallet: {
      address: customWallet,
      connected: customWallet?.length > 30,
      name: "Custom",
    },
  };
  function handleConnection() {
    if (chainData)
      return (
        (chainData?.networkType === "evm" && address && address?.length) ||
        // && isConnected
        (chainData?.networkType === "sol" &&
          isFullyConnected &&
          primaryWallet !== null) ||
        (chainData?.networkType === "cosmos" && isCosmosConnected) ||
        (chainData?.networkType === "tron" && connected) ||
        (show === "to" && customWallet?.length > 30)
      );
    else return;
  }
  function handleEVMDisable(connectWalletField) {
    if (
      (connectWalletField === "from" &&
        (fromChain?.networkType?.toLowerCase() === "sol" ||
          fromChain?.networkType?.toLowerCase() === "tron")) ||
      (connectWalletField === "to" &&
        (toChain?.networkType?.toLowerCase() === "sol" ||
          toChain?.networkType?.toLowerCase() === "tron"))
    ) {
      return true;
    } else {
      return false;
    }
  }
  function handleSOLDisable(connectWalletField) {
    if (
      (connectWalletField === "from" &&
        (fromChain?.networkType?.toLowerCase() === "evm" ||
          fromChain?.networkType?.toLowerCase() === "tron")) ||
      (connectWalletField === "to" &&
        (toChain?.networkType?.toLowerCase() === "evm" ||
          toChain?.networkType?.toLowerCase() === "tron"))
    ) {
      return true;
    } else {
      return false;
    }
  }
  function handleTRONDisable(connectWalletField) {
    if (
      (connectWalletField === "from" &&
        (fromChain?.networkType?.toLowerCase() === "sol" ||
          fromChain?.networkType?.toLowerCase() === "evm")) ||
      (connectWalletField === "to" &&
        (toChain?.networkType?.toLowerCase() === "sol" ||
          toChain?.networkType?.toLowerCase() === "evm"))
    ) {
      return true;
    } else {
      return false;
    }
  }
  function getWallet(chainData) {
    if (chainData)
      if (chainData?.networkType === "evm") {
        return evmWallet;
      } else if (chainData?.networkType === "sol") {
        return solWallet;
      } else if (chainData?.networkType === "tron") {
        return tronWallet;
      } else if (chainData?.networkType === "cosmos") {
        return cosmosWallet;
      } else {
        if (customWallet?.length > 30) return customWalletDetail;
        else return {};
      }
    else {
      return;
    }
  }
  function getBothWalletConnection(fromNetwork, toNetwork) {
    if (fromNetwork && toNetwork)
      return (fromNetwork == "cosmos"
        ? cosmosData?.[fromChain?.chainId]?.address?.length
        : walletAddress[fromNetwork]?.address?.length) &&
        (toNetwork == "cosmos"
          ? cosmosData?.[toChain?.chainId]?.address?.length
          : walletAddress[toNetwork]?.address?.length)
        ? // ||
          // customWallet?.length > 30)
          // &&
          // !toDisconnect
          true
        : false;
    else {
      return false;
    }
  }

  function getConnectWallet(fromNetwork, toNetwork) {
    if (fromNetwork && toNetwork) {
      if (!walletAddress[fromNetwork?.networkType]?.connected) {
        return fromNetwork?.name;
      } else if (!walletAddress[toNetwork?.networkType]?.connected) {
        return toNetwork?.name;
      } else if (toDisconnect) {
        return toNetwork?.name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  function getAllConnections() {
    let connections = [];

    if (evmConnections.length) {
      evmConnections.forEach((connection) => {
        connections.push(connection.accounts[0]);
      });
    }
    if (wallets.length) {
      wallets.forEach((wallet) => {
        connections.push(wallet.address);
      });
    }
    if (cosmosData && cosmosData?.["cosmoshub-4"]?.bech32Address) {
      connections.push(cosmosData["cosmoshub-4"].bech32Address);
    }
    return connections;
  }
  return {
    isAnyWalletConnected: handleConnection(),
    getWallet,
    handleEVMDisable,
    handleSOLDisable,
    handleTRONDisable,
    getBothWalletConnection,
    getConnectWallet,
    refetch: reconnect,
    getAllConnections,
  };
}
