import useStore from "../../zustand/store";
import truncate from "../../utils/truncate";
import { useEffect, useState, useRef } from "react";
import WalletIcon from "../Svg/walletIcon";
import { useAccount, useBalance } from "wagmi";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import convertNative from "../../utils/convertNative";
import useWalletDetails from "../../hooks/useWalletDetails";
import { Skeleton } from "@mui/material";
import images from "../../images";
import fixChar from "../../utils/fixChar";
import fixString from "../../utils/fixString";
export default function InputComp({
  amount,
  setAmount,
  fetchBalance,
  getCoinBalance,
  isBalanceLoading,
}) {
  const configuration = useStore((state) => state.configuration);
  const fromCoin = useStore((state) => state.fromCoin);
  const fromChain = useStore((state) => state.fromChain);
  const { isAnyWalletConnected } = useWalletDetails({ chainData: fromChain });
  const [inputWidth, setInputWidth] = useState(40);
  const { address } = useAccount();
  const inputContainerRef = useRef();
  const { defaultCoin } = images;
  const balances = useBalance({
    address: address,
    token: convertNative(fromCoin?.address || ""),
    chainId: Number(fromChain?.chainId || 0),
    enabled: fromChain?.networkType == "evm" ? true : false,
  });
  const inputRef = useRef();
  // useEffect(() => {
  //   setInputWidth(
  //     inputContainerRef.current?.offsetWidth +
  //       (80 / 100) * inputContainerRef.current?.offsetWidth || 70
  //   );
  // }, [amount]);
  const selectedCoinbalance = getCoinBalance;
  // truncate(
  //   (fetchBalance.data?.data?.[fromChain?.networkType]?.[fromChain.chainId]?.[
  //     fromCoin.address
  //   ]?.balance || 0) /
  //     (Math.pow(
  //       10,
  //       fetchBalance.data?.data?.[fromChain?.networkType]?.[
  //         fromChain.chainId
  //       ]?.[fromCoin.address]?.asset?.decimals || 0
  //     ) || 0),
  //   6
  // );
  return (
    <div className=" bw-mb-5 bw-mt-4">
      <p className="bw-text-sm bw-font-medium bw-mb-1 bw-text-text-secondary">
        Give {fromCoin?.name || ""}
      </p>
      <div
        style={{
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "1px 1px 7px rgba(0, 0, 0, 0.15)",
        }}
        className="bw-w-full bw-h-[80px] bw-flex bw-gap-x-1 bw-items-center bw-px-2  bw-bg-background-card bw-rounded-md bw-border bw-border-border-primary"
      >
        <div className="bw-w-full bw-flex bw-gap-x-2 bw-mt-5 bw-items-start bw-h-full">
          <div className=" bw-flex bw-items-center">
            {fromCoin?.image ? (
              <div className="bw-rounded-[50%]  bw-bg-background-coin bw-min-w-[40px] bw-w-[40px] bw-min-h-[40px] bw-flex bw-justify-center bw-items-center  bw-border bw-border-border-primary">
                <img
                  src={fromCoin?.image}
                  width={39}
                  height={39}
                  alt="img"
                  className="bw-rounded-[50%] bw-min-w-[39px] bw-min-h-[39px]"
                />
              </div>
            ) : (
              <div className="bw-min-w-[40px] bw-w-[40px] bw-border bw-border-transparent bw-min-h-[40px] bw-rounded-[50%] bw-bg-background-coin">
                <img
                  src={defaultCoin}
                  width={39}
                  height={39}
                  alt="img"
                  className="bw-rounded-[50%] bw-min-w-[39px] bw-min-h-[39px]"
                />
              </div>
            )}
          </div>
          <div className="bw-w-full">
            <div
              onClick={() => {
                inputRef.current.focus();
              }}
              className=" "
            >
              <div>
                <span
                  className="bw-invisible bw-absolute"
                  ref={inputContainerRef}
                >
                  {amount}0
                </span>
                <input
                  ref={inputRef}
                  pattern="\\d*"
                  inputMode="decimal"
                  autoFocus
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  value={amount}
                  onChange={(e) => {
                    if (e.target.value.length <= 11) setAmount(e.target.value);
                  }}
                  // style={{ width: inputWidth }}
                  onKeyPress={(e) => {
                    if (e.key === "-" || e.key === "+") {
                      e.preventDefault();
                    }
                  }}
                  placeholder="0.0"
                  className="bw-text-3xl focus:bw-placeholder:bw-opacity-0  placeholder:bw-text-left bw-ml-auto bw-mb-1  bw-pl-1 bw-min-w-[45px] bw-max-w-[200px]  bw-font-medium placeholder:bw-text-text-placeholder bw-text-text-primary bw-bg-transparent"
                />
              </div>
            </div>
            <div>
              {fromCoin.coin.length && isAnyWalletConnected ? (
                isBalanceLoading ? (
                  <Skeleton className="bw-bg-background-loaderbar bw-rounded-md bw-h-[18px] bw-w-[100px]" />
                ) : (
                  <div className="bw-flex bw-items-center bw-gap-x-1">
                    <WalletIcon fill={"var(--tw-text-secondary)"} />
                    <p className="bw-text-xs bw-font-medium bw-text-text-secondary">
                      {fixString(selectedCoinbalance?.toString() || "", 11)}{" "}
                      {fromCoin?.symbol || ""}
                    </p>
                    <button
                      className="bw-text-xs bw-font-normal bw-w-[37px] bw-h-[18px] bw-rounded-[4px] bw-px-1 bw-border bw-border-border-primary bw-text-text-secondary"
                      onClick={() => {
                        setAmount(
                          fixString(selectedCoinbalance?.toString() || "", 11)
                        );
                      }}
                    >
                      MAX
                    </button>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="bw-flex bw-justify-between bw-items-center">
          <div className="bw-flex bw-items-center bw-gap-x-1">
            <p
              className={` bw-font-normal bw-pl-2 ${
                amount.length
                  ? "bw-text-text-secondary bw-text-sm"
                  : "bw-text-text-placeholder bw-text-[10px]"
              }`}
            >
              ${fixString(Number(amount) * fromCoin?.lastPrice || 0, 11)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
