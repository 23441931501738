import React, { useEffect, useState } from "react";
import {
  useStargateSigningClient,
  useCosmWasmSigningClient,
  useAccount,
} from "graz";
import { Registry } from "@cosmjs/proto-signing";
import { defaultRegistryTypes } from "@cosmjs/stargate";
import { MsgTransfer } from "cosmjs-types/ibc/applications/transfer/v1/tx";
import useStore from "../zustand/store";
import executeRouteCosmos from "../utils/cosmosExecution";
export default function useCosmosTxn() {
  const [cosmosError, setCosmosError] = useState(false);
  const [cosmosData, setCosmosData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const cosmosChainIds = useStore((state) => state.cosmosChainIds);

  const { data: signingClient } = useCosmWasmSigningClient({
    chainId: cosmosChainIds,
    multiChain: true,
  });
  const fromChain = useStore((state) => state.fromChain);
  const { data: stargateClient, isLoading } = useStargateSigningClient({
    chainId: [fromChain.chainId],
    multiChain: true,
  });
  const persist = useStore((state) => state.persist);
  const { data } = useAccount({
    chainId: cosmosChainIds,
    multiChain: true,
  });
  function resetCosmosData() {
    setCosmosError(false);
    setErrorMessage("");
    setCosmosData(null);
  }
  const getCustomRegistry = () => {
    const registry = new Registry(defaultRegistryTypes);
    registry.register("/ibc.applications.transfer.v1.MsgTransfer", MsgTransfer);
    return registry;
  };

  async function handleCosmosTransaction(txndata) {
    if (stargateClient && txndata) {
      try {
        setCosmosError(false);
        setErrorMessage("");
        let txn = await executeRouteCosmos(
          stargateClient?.[fromChain.chainId],
          data?.[fromChain.chainId]?.bech32Address,
          {
            transactionRequest: { ...txndata.txnData.txnCosmos },
          },
          true,
          persist?.route
        );
        setCosmosData({ signature: txn.transactionHash });
      } catch (err) {
        setCosmosError(true);
        setErrorMessage({ message: err.message || err.details || "" });
      }
    }
  }
  return {
    handleCosmosTransaction,
    cosmosData,
    cosmosError,
    cosmosErrorMessgae: errorMessage,
    resetCosmosData,
  };
}
