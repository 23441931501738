import RoundedButton from "../Button/RoundedButton";
import React, { useState } from "react";
import Settings from "../Modal/Settings";
import useStore from "../../zustand/store";
import SettingsSvg from "../Svg/settings";
import HeadingContainer from "./HeadingContainer";
export default function Header() {
  const [open, setOpen] = useState(false);
  const fromChain = useStore((state) => state.fromChain);

  function handleClose() {
    setOpen(false);
  }
  return (
    <>
      <div className="bw-flex bw-w-full -bw-mt-2 bw-mb-3 bw-items-center bw-justify-end">
        <HeadingContainer />
        <div
          onClick={() => fromChain?.networkType !== "cosmos" && setOpen(true)}
          className={`bw-flex bw-relative bw-right-0 bw-items-center bw-gap-x-2`}
        >
          <RoundedButton classnames={" "}>
            <SettingsSvg fill={"var(--tw-text-secondary)"} />
          </RoundedButton>
        </div>
      </div>
      <Settings open={open} handleClose={handleClose} />
    </>
  );
}
